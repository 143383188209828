.changeLang {
    color: #006688 !important;
    text-transform: uppercase;
    background-color: #fd8805;
    margin: auto 0;
}
.changeLang:hover {
    color: #5350e6 !important;
    cursor: pointer;
}
.changeLang[aria-checked="true"]{
    background-color: #5350e6;
}

.submitBtn {
    width: fit-content ;
    background: rgb(255, 151, 0);
    height: 23px;
    color: white;
    text-transform: uppercase;
    border: 1px solid transparent;
    z-index: 1;
    line-height: 15px;
    font-weight: 550;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 1px 1px black;
    display: flex;
}
.submitBtn:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 23px;
    left: 0;
    font-weight: 600;
    z-index: -1;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
    transition: all 0.3s ease-in-out;
}
.submitBtn:hover {
    font-weight: 600;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    color: white;
}
.submitBtn:hover:after {
    top: 0;
    bottom: 0;
    height: 100%;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
}
.submitBtn:active {
    color: white;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.submitBtn:focus {
    color: white;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.Header {
    position: sticky;
    padding: 10px;
    height: 60px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 1px 10px 0 rgba(0,0,0,.16), 0 1px 10px 0 rgba(0,0,0,.12);
    -moz-box-shadow:0 1px 10px 0 rgba(0,0,0,.16), 0 1px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.16), 0 1px 10px 0 rgba(0,0,0,.12);
}
/* @media only screen and(max-width:500px){
    .logo {
        display: flex !important;
    }
} */