.news-card-wrapper {
  height: 100%;
  width: 100%;
  background-color: white;
  display: inline-block;
}
.news-card-title-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.news-card-title-right {
  font-size: 16px;
  color: #ffa401;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 10px;
}
.news-card-title-right::before {
  content: "tin ";
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
}
.news-card-title-right-en {
  font-size: 16px;
  color: #ffa401;
  font-weight: bold;
  text-transform: uppercase;
}
.news-card-title-right-en::after {
  content: " news";
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
}
.post-wrapper {
  /* height: 92px; */
  display: flex;
  
  /* margin-bottom: 15px; */
}
.img-wrapper {
  width: 140px;
  height: 85px;
  border: 1px solid #ccc;
  display: flex;
}
.post-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  text-align: justify;
  line-height: 16px;
  font-weight: bold;
}
.post-content-wrapper a {
  color: #0076a7;
}
.post-content-wrapper .itemHover:hover {
  color: #0076a7;
}
.post-content-wrapper a:hover {
  color: #f13636;
}
.post-footer {
  color: #989a99;
  font-size: 11px;
  font-weight: normal;
}
.post-footer a {
  font-weight: normal;
  font-size: 13px;
  color: #989a99 !important;
}
.post-footer a:hover {
  color: #f13636 !important;
}
.post-footer::before {
  content: "\01F550";
  font-size: 10px;
  margin-right: 3px;
}
