.news-wrapper,
.news-title-wrapper,
.news-title,
.news-content-wrapper {
  width: 100%;
}
.news-content-wrapper {
  margin-top: 15px;
}
.news-title h2 {
  margin-top: 15px;
  color: #ffa401;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
}
.news-title {
  display: flex;
  justify-content: center;
}
.child-menu {
  padding-bottom: 0 !important;
  font-weight: bold;
}
.child-menu:hover {
  padding-bottom: 0 !important;
  color: #ffa401 !important;
}
.child-menu.ant-menu-item {
  padding-bottom: 0 !important;
}
.child-menu.ant-menu-item-selected {
  color: #ffa401 !important;
  padding-bottom: 0 !important;
}
