.card-wrapper {
  height: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
}
.icon-wrapper {
  background-color: #ffa300;
  height: 55px;
  width: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.icon-wrapper::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid #ffa300;
  border-top: 5px solid #ffa300;
  border-bottom: 5px solid transparent;
  right: 0px;
  bottom: -10px;
}
.card-body {
  margin: 0px 15px;
  text-align: justify;
  font-size: 16px;
}
.card-cover-wrapper {
  width: 100%;
}

.card-title-wrapper {
  margin-top: 15px;
}
.card-content-wrapper {
  margin-top: 15px;
  margin-bottom: 45px;
}
.card-title-wrapper {
  font-weight: bold;
}

.card-title-wrapper a:hover {
  color: #f13636 !important;
}
.card-content-wrapper {
  line-height: 20px;
}
.detail-link {
  position: absolute;
  bottom: 10px;
  left: 0;
  font-weight: 700;
}

.card-footer-wrapper a:hover {
  color: #f13636 !important;
}
.detail-link::after {
  content: "  \02C3";
  font-weight: bold;
}
.card-wrapper a {
  color: #006688;
}
