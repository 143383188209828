/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.fundo{
    -webkit-animation: scales 3s alternate  infinite;
            animation: scales 3s alternate  infinite;
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
  }
  .pao-baixo{
     -webkit-animation: rotatepao 14s cubic-bezier(.1,.49,.41,.97)  infinite;
             animation: rotatepao 14s cubic-bezier(.1,.49,.41,.97)  infinite;
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
  }
  .pao-cima{
     -webkit-animation: rotatepao 7s 1s cubic-bezier(.1,.49,.41,.97)  infinite;
             animation: rotatepao 7s 1s cubic-bezier(.1,.49,.41,.97)  infinite;
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
  }
  .olhos{-webkit-animation: olhos   2s  alternate  infinite;animation: olhos   2s  alternate  infinite;
     -webkit-transform-origin: center;
         -ms-transform-origin: center;
             transform-origin: center;
  }
  
  .left-sparks{-webkit-animation: left-sparks   4s  alternate  infinite;animation: left-sparks   4s  alternate  infinite;
        -webkit-transform-origin: 150px 156px;
            -ms-transform-origin: 150px 156px;
                transform-origin: 150px 156px;
  }
  
  .right-sparks{-webkit-animation: left-sparks   4s  alternate  infinite;animation: left-sparks   4s  alternate  infinite;
        -webkit-transform-origin: 310px 150px;
            -ms-transform-origin: 310px 150px;
                transform-origin: 310px 150px;
  }
  
  .olhos{-webkit-animation: olhos   2s  alternate  infinite;animation: olhos   2s  alternate  infinite;
     -webkit-transform-origin: center;
         -ms-transform-origin: center;
             transform-origin: center;
  }
  @-webkit-keyframes scales{
    from { -webkit-transform: scale(0.98); transform: scale(0.98)}
    to{ -webkit-transform: scale(1); transform: scale(1)}
  }
  @keyframes scales{
    from { -webkit-transform: scale(0.98); transform: scale(0.98)}
    to{ -webkit-transform: scale(1); transform: scale(1)}
  }
  
  @-webkit-keyframes rotatepao{
    0% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg)}
    50% , 60%{ -webkit-transform:  rotate(-20deg); transform:  rotate(-20deg)}
    100%{  -webkit-transform:  rotate(0deg);  transform:  rotate(0deg) }
   
  }
  
  @keyframes rotatepao{
    0% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg)}
    50% , 60%{ -webkit-transform:  rotate(-20deg); transform:  rotate(-20deg)}
    100%{  -webkit-transform:  rotate(0deg);  transform:  rotate(0deg) }
   
  }
  
  @-webkit-keyframes olhos{
    0%{
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
    }
     100%{
      -webkit-transform: rotateX(30deg);
              transform: rotateX(30deg);
    }
  }
  
  @keyframes olhos{
    0%{
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
    }
     100%{
      -webkit-transform: rotateX(30deg);
              transform: rotateX(30deg);
    }
  }
  
  @-webkit-keyframes left-sparks{
    0%{
      opacity: 0; 
    }
    
  }
  
  @keyframes left-sparks{
    0%{
      opacity: 0; 
    }
    
  }
  
  
  .main{
    min-height: 600px;
    margin: 0px auto;
    width: auto;
    max-width: 460px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .path {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    -webkit-animation: dash 4s   alternate infinite;
            animation: dash 4s   alternate infinite;
  }
  
  @-webkit-keyframes dash{
     0%, 30%{
       fill: 4B4B62;
      stroke-dashoffset:   0;
    }
     80%,100%{
       fill: transparent;
      stroke-dashoffset:  -200;
    }
     
    
  }
  
  @keyframes dash{
     0%, 30%{
       fill: 4B4B62;
      stroke-dashoffset:   0;
    }
     80%,100%{
       fill: transparent;
      stroke-dashoffset:  -200;
    }
     
    
  }