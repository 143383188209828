.formItemWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* print */
.spaceBetween {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.logoContainer {
    max-width: 130px;
}
.logo {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.shipmentDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.consolTable {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}
.consolTable, .consolTable th, .consolTable td {
    border:1px solid black;
    text-align: center;
    padding: 4px 2px;
}
.checkBoxContainer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-bottom: 10px;
    gap: 5px;
}
.dateContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.col {
    border: 1px dotted rgb(119, 114, 114);
    min-height: 100px;
}
.col:nth-of-type(2) {
    border-left: transparent;
}
.colTitle {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}