.ant-card-head-title {
  font-weight: bold;
  font-size: 17px;
}
.ant-card-body {
  text-align: justify;
  font-size: 16px;
  white-space: pre-line;
}
.ant-card-body a {
  text-align: justify;
  font-size: 16px;
  white-space: pre-line;
  font-size: 16px;
  font-weight: bold;
  color: #0076a7;
}
.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffa401;
}
.instructor-image-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}
.instructor-image-wrapper img {
  max-width: 80%;
}
.ant-steps-navigation .ant-steps-item::after {
  border: 1px solid #feb228;
  border-bottom: none;
  border-left: none;
}
.guide-content .ant-steps-item-container {
  opacity: 0.5;
}
.guide-content .ant-steps-item-active .ant-steps-item-container {
  opacity: 1;
}
.guide-content .ant-collapse-content-box p {
  padding-left: 4%;
}
.guide-content .ant-collapse-header {
  font-weight: bold;
}
