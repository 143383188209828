.introduction-content {
  width: 100%;
}
.introduction-content-wrapper {
  width: 100%;
  margin-top: 15px;
}
.introduction-title {
  display: flex;
  justify-content: center;
  text-transform: uppercase;

}
