.checkout-content {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.checkout-content-wrapper {
  display: flex;
  justify-content: center;
  margin: auto 30px;
}
.ant-tabs.ant-tabs-top {
  width: 100%;
}
.checkout-content .ant-tabs-tab {
  font-size: 14px;
  background-color: #fd8805;
  border-top-right-radius: 10px;
  padding: 3px 10px;
  text-transform: capitalize;
  margin-top: 20px;
}
.checkout-content .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 0 !important;
}
.checkout-content .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-bottom: none !important;
  color: white !important;
}
.checkout-content .ant-tabs-tab + .ant-tabs-tab {
  margin: 20px 0 0 5px;
  padding: 11px 15px;
  font-weight: bold;
}
.form-receiver .ant-form-item-label label,
.form-einvoice-query .ant-form-item-label label,
.form-einvoice-payment .ant-form-item-label label,
.form-invoice-info .ant-form-item-label label,
.awb-payment .ant-form-item .ant-form-item-label label {
  color: #fb4312;
  font-size: 12px;
  font-weight: 600;
}
.form-receiver .ant-input,
.form-receiver .ant-picker,
.form-invoice-info .ant-input,
.form-invoice-info .ant-picker,
.awb-payment .ant-input,
.ant-input .ant-picker {
  width: 100%;
  height: 40px;
}
.btn-payment {
  color: white;
  background: #fd8805;
  border-radius: 4px;
}
.btn-payment > span {
  font-size: 14px;
}
.btn-payment:hover {
  color: white;
  background: #fd8805;
}
.horizontal-controls {
  display: flex;
  justify-content: space-between;
}
.row-selected {
  background-color: #dcf4ff;
}
.checkout-content .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #dcf4ff;
}
.hawb-fee-content .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #dcf4ff;
}
.table-payment .ant-table-footer {
  font-weight: bold;
}
.table-agent-list {
  cursor: default;
}
.table-agent-list .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #dcf4ff;
}
td.awb-table-currency {
  text-align: right !important;
}
.table-payment-charge-detail .ant-table-summary {
  background-color: #ddd;
  font-weight: bold;
}
.table-payment .ant-table-summary {
  background-color: #ddd;
  font-weight: bold;
}
.table-payment-charge-detail .ant-table-summary td {
  border: 1px solid #000;
}
.barcode-wrapper {
  height: 176mm;
  width: 250mm;
  margin-left: 20px;
  display: flex;
  justify-content: center;
}
.barcode-wrapper h3 {
  font-size: 32px;
}
.account-info .ant-form-item-label label {
  font-size: 13px;
}
.table-payment .ant-table-summary {
  line-height: 10px;
}
.exit-btn {
  font-size: 14px;
  background-color: #fd8805;
  border-top-right-radius: 10px;
  text-transform: capitalize;
  margin-top: 25px;
  height: 44px;
}
.exit-btn:hover {
  background-color: #fd8805;
}

.exit-btn * {
  font-weight: bold;
}
