@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
	margin: 0;
}

.introduction-body hr {
	color: white;
	border-top: 1px dotted #999 !important;
	margin-bottom: 10px;
}

*::selection {
  background-color: #e74c3c;
}
.ant-layout {
  background: #fff;
  /* font-family: Arial, Helvetica, sans-serif; */
}
.text-bold {
  font-weight: bold;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.ant-picker-dropdown.hide-footer .ant-picker-footer {
  display: none;
}
.ant-layout-header {
  background: #fff;
  height: 105px;
  width: 100%;
  line-height: 30px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 6px;
  margin-bottom: 10px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
  align-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}
.ant-layout-content {
  min-height: 800px;
  background: #fff;
  margin-bottom: 30px;
  /* max-width: 1920px; */
}
.ant-layout-footer {
  background: #ffa401;
  padding: 0;
  padding-bottom: 10px !important;
}
.ant-notification {
  right: 50% !important;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
.header-bold .ant-modal-header .ant-modal-title {
  font-weight: bold;
}
.background-gray .ant-modal-header {
  background-color: #ddd;
}
.main-content-wrapper {
  margin-left: 10px;
}
input[type="number"]:hover::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]:focus::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.table-payment .ant-radio {
  margin: 0;
}

.ant-form-item  {
  margin-bottom: 10px !important;
}
.esid-tab-checkout .ant-btn {
  background-color: #ff9700;
}
.esidInvoiceInfo table > thead tr th {
  color: red;
  font-weight: 600;
}
.paymentOptions {
  display: flex;
  flex-direction: column;
}
.paymentOptions .ant-radio {
  margin-left: 0 !important;
}
.paymentOptions .ant-radio-wrapper {
  align-items: center;
  margin-bottom: 15px;
  grid-gap: 5px;
  gap: 5px;
}
.paymentOptions .ant-radio-wrapper-checked span.payment-label {
  font-weight: 700;
}
label[for = 'airway-bill_natureOfGoods'] {
  width: 100%;
}

.cursorPointer {
  cursor: pointer;
}

/*
 * CKEditor 5 (v39.0.2) content styles.
 * Generated on Wed, 06 Sep 2023 11:58:34 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

 :root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-selector-caption-background: hsl(0, 0%, 97%);
  --ck-color-selector-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

/* @ckeditor/ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-selector-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-selector-caption-background);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: .4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
  table-layout: fixed;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  overflow-wrap: break-word;
  position: relative;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: .3em .6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  user-select: none;
}
/* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: 16px;
  width: var(--ck-todo-list-checkmark-size);
  height: 16px;
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc( 16px / 3 );
  left: calc( var(--ck-todo-list-checkmark-size) / 3 );
  top: calc( 16px / 5.3 );
  top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  width: calc( 16px / 5.3 );
  width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
  height: calc( 16px / 2.6 );
  height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc( 16px / 8 ) calc( 16px / 8 ) 0;
  border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-image-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-image-caption-background);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol {
  list-style-type: decimal;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol {
  list-style-type: lower-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol {
  list-style-type: lower-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol {
  list-style-type: upper-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol ol {
  list-style-type: upper-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul {
  list-style-type: disc;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul {
  list-style-type: circle;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul {
  list-style-type: square;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul ul {
  list-style-type: square;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: hsl(60, 97%, 73%);
  background-color: var(--ck-highlight-marker-yellow);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: hsl(120, 93%, 68%);
  background-color: var(--ck-highlight-marker-green);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: hsl(345, 96%, 73%);
  background-color: var(--ck-highlight-marker-pink);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: hsl(201, 97%, 72%);
  background-color: var(--ck-highlight-marker-blue);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: hsl(0, 85%, 49%);
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: hsl(112, 100%, 27%);
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - 1.5em);
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: 1.5em;
  margin-right: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: calc(1.5em / 2);
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: calc(1.5em / 2);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: calc(1.5em / 2);
  margin-right: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: calc(1.5em / 2);
  margin-left: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: .15em;
  border-radius: 2px;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: .7em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: .85em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* @ckeditor/ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: hsla(341, 100%, 30%, 0.1);
  background: var(--ck-color-mention-background);
  color: hsl(341, 100%, 30%);
  color: var(--ck-color-mention-text);
}
/* @ckeditor/ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
@media print {
  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
      padding: 0;
  }
  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
      display: none;
  }
}

.menu-logo {
  margin-right: 5px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffdc9b;
}
@media screen and (max-width: 992px) {
  .icon-close {
    color: #9e2e2e;
  }
  .icon-menu {
    color: #fda400;
  }
  .icon-menu:hover {
    color: #eeba5a;
  }
  .ant-menu-submenu-title:hover {
    color: #fff;
  }
  .ant-menu-submenu-title,
  .ant-menu-submenu-title i {
    color: #fff;
    text-transform: uppercase;
  }
  .ant-menu-submenu-title:hover i {
    color: #fff !important;
  }
  .ant-menu-item,
  .ant-menu-item-selected a {
    color: #006688;
  }
  .ant-menu-item:hover {
    background: #ec8a46;
  }
  .ant-menu-item:hover > .ant-menu-submenu-title i {
    color: #fff;
  }
  .ant-menu-item:hover a {
    color: #fff;
  }
  .ant-menu-item:hover > .ant-menu-submenu-title i {
    color: #fff !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #ec8a46;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    color: #fff;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-color: transparent;
  }
}

.header .header-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 20px 10px rgba(0, 33, 59, 0.04);
}
.ant-layout-header {
  padding: 0;
  margin: 0;
}

.nav-banner-content-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.header .logo-wrapper,
.navigator-wrapper {
  display: flex;
  align-items: center;
}
.banner-nav-wrapper ul {
  display: flex;
  justify-content: center;
}
.nav-banner-content-email {
  color: #ffa401;
}
/* label.ant-radio-wrapper{
  flex-direction: row-reverse;
} */
/* label.ant-radio-wrapper .ant-radio{
  margin-left: 5px;
} */
@media screen and (max-width: 992px) {
  .ant-col.ant-col-3.logo-wrapper {
    display: none;
  }
  .nav-banner-content-wrapper {
    background: #fda400;
  }
  .nav-banner-content-phone h4 {
    color: #fff !important;
  }
  .header .logo-wrapper,
  .navigator-wrapper {
    height: 100%;
    min-width: 70px;
    margin: 0;
  }
  .nav-banner-content-email {
    display: none;
  }
  .banner-nav-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .banner-nav-wrapper span {
    margin-right: 10px;
  }
}

.header .navigator {
  width: 100%;
  padding-bottom: 5px;
  border: none;
  text-transform: uppercase;
}
.header .navigator li {
  color: #ffa401;
  font-weight: bold;
  font-size: 18px;
}
.header .navigator li a {
  color: #ffa401;
  font-weight: bold;
  font-size: 18px;
}

@media screen and (max-width: 1355px) {
  .header .navigator li {
    color: #ffa401;
    font-weight: bold;
    font-size: 14px;
  }
  .header .navigator li a {
    color: #ffa401;
    font-weight: bold;
    font-size: 14px;
  }
}

.header .nav-banner-content {
  font-size: 18px;
  margin-right: 10px;
}
.header .navigator li div::after {
  content: "\25BC";
  font-size: 13px;
}
.header .nav-banner-content a {
  font-size: 16px;
  color: #fd8805;
  text-transform: uppercase;
  font-weight: bold;
}
.header .change-lang {
  color: #006688 !important;
  text-transform: uppercase;
}
.header .change-lang:hover {
  color: #5350e6 !important;
  cursor: pointer;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border: transparent;
  padding-bottom: 2px !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  padding-bottom: 2px !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  padding-bottom: 4px !important;
}
.ant-menu-vertical.ant-menu-sub {
  padding: 5px;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  margin: 5px;
  border-bottom: 0.5px dashed #ddd;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item:hover {
  background: #ffdc9b;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item a {
  color: #006688 !important;
}
.header .change-lang {
  background-color: #fd8805;
}
.header .ant-switch-checked {
  background-color: #5350e6;
}

.footer .navigator {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
  border: none;
  line-height: 18px;
  background: #ffa401;
}
.footer .navigator li {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
}
.footer .navigator li a {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.footer .navigator li a:hover {
  color: rgba(0, 0, 0, 0.85);
}
@media screen and (max-width: 700px){
  .footer .footer-links {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;
    text-align: center;
  }
}
.footer .footer-links,
.footer-slides {
  width: 100%;
}
.footer .footer-links {
  margin-top: 20px;
  background-color: #ffa401;
  display: flex;
  justify-content: space-evenly;
}

.home-content-wrapper {
  padding: 0 !important;
  background: white;
  display: flex;
  justify-content: center;
}

.home-control-wrapper {
  width: 100%;
  /*   position: relative;
 */
  display: flex;
  justify-content: center;
}
.home-control {
  /*   position: absolute;
 */ /* top: -50%; */
  width: 997px;
  border: 1px solid #ccc;
}
.ant-carousel .slick-dots-bottom {
  bottom: 80px;
}
.home-control {
  background: rgba(190, 181, 181, 0.4);
  padding: 20px;
}
.tab {
  float: left;
  display: flex;
  justify-content: space-between;
  height: 45px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}
.tab:first-child {
  margin: 0 5px 0 0;
  background: white;
}
.tab:first-child h2 {
  color: #fda400;
  font-weight: 700;
}
.tab:not(:first-child) {
  margin: 0 5px 0 0;
  background: rgba(255, 251, 251, 0.5);
}
.tab:not(:first-child) a h2 {
  color: rgba(30, 145, 253, 0.5);
  font-weight: 700;
}
.home-control-wrapper .tab:not(:first-child):hover {
  background: rgba(255, 251, 251, 0.616);
}
.home-control-wrapper .tab:not(:first-child):hover a h2 {
  color: rgba(30, 145, 253, 0.747);
}
.tab-content-container {
  width: 100%;
  background: white;
  float: left;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 25px 15px 25px 25px;
  line-height: 25px;
  justify-content: left;
}
.ant-form-item-label > label {
  font-size: 16px;
}
.home-control .ant-btn {
  background: rgb(255, 151, 0);
  height: 23px;
  color: white;
  text-transform: uppercase;
  border: 1px solid transparent;
  z-index: 1;
  line-height: 15px;
  width: 41px;
  font-weight: 550;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 1px 1px black;
  display: flex;
  justify-content: center;
  margin-left: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.home-control .ant-btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 23px;
  left: 0;
  font-weight: 600;
  z-index: -1;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
  transition: all 0.3s ease-in-out;
}
.home-control .ant-btn:hover {
  font-weight: 600;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  color: white;
}
.home-control .ant-btn:hover:after {
  top: 0;
  bottom: 0;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
}
.home-control .ant-btn:active {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.home-control .ant-btn:focus {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.ant-radio {
  margin-left: 40px;
}
.ant-radio-wrapper {
  font-size: 16px !important;
}
.content-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.post-content-wrapper {
  /* margin-left: 15px; */
}
.ant-input-number-input-wrap {
  background-color: #e8f0fe;
}
.ant-input-number-input {
  height: 100%;
}
@media screen and (max-width: 500px) {
  .home-control-wrapper {
    height: 210px;
  }
}
@media screen and (max-width: 768px) {
  .home-control {
    margin-top: 60px;
    margin-bottom: 20px;
    border: 1px solid #808080;
  }
  .home-control {
    background: #ffffff;
    padding: 20px;
  }

  .home-control-wrapper .tab-show {
    /* display: none; */
    padding: 0 !important;
    margin: 0;
    font-size: 12px;
    height: 35px;
  }
  .home-control-wrapper .tab-show h2 {
    margin: 0 !important;
  }

  .tab-content-container {
    padding: 0;
    height: none;
  }

  .content-wrapper {
    margin-bottom: 0;
  }
  .home-btn .ant-btn {
    width: 100% !important;
    border-color: #ccc;
    color: #337ab7;
    font-weight: 700;
  }

  .ant-col.ant-col-22.home-content.ant-col-md-22.ant-col-lg-6 {
    padding: 0;
  }
}

.card-wrapper {
  height: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
}
.icon-wrapper {
  background-color: #ffa300;
  height: 55px;
  width: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.icon-wrapper::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid #ffa300;
  border-top: 5px solid #ffa300;
  border-bottom: 5px solid transparent;
  right: 0px;
  bottom: -10px;
}
.card-body {
  margin: 0px 15px;
  text-align: justify;
  font-size: 16px;
}
.card-cover-wrapper {
  width: 100%;
}

.card-title-wrapper {
  margin-top: 15px;
}
.card-content-wrapper {
  margin-top: 15px;
  margin-bottom: 45px;
}
.card-title-wrapper {
  font-weight: bold;
}

.card-title-wrapper a:hover {
  color: #f13636 !important;
}
.card-content-wrapper {
  line-height: 20px;
}
.detail-link {
  position: absolute;
  bottom: 10px;
  left: 0;
  font-weight: 700;
}

.card-footer-wrapper a:hover {
  color: #f13636 !important;
}
.detail-link::after {
  content: "  \02C3";
  font-weight: bold;
}
.card-wrapper a {
  color: #006688;
}

.news-card-wrapper {
  height: 100%;
  width: 100%;
  background-color: white;
  display: inline-block;
}
.news-card-title-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.news-card-title-right {
  font-size: 16px;
  color: #ffa401;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 10px;
}
.news-card-title-right::before {
  content: "tin ";
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
}
.news-card-title-right-en {
  font-size: 16px;
  color: #ffa401;
  font-weight: bold;
  text-transform: uppercase;
}
.news-card-title-right-en::after {
  content: " news";
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
}
.post-wrapper {
  /* height: 92px; */
  display: flex;
  
  /* margin-bottom: 15px; */
}
.img-wrapper {
  width: 140px;
  height: 85px;
  border: 1px solid #ccc;
  display: flex;
}
.post-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  text-align: justify;
  line-height: 16px;
  font-weight: bold;
}
.post-content-wrapper a {
  color: #0076a7;
}
.post-content-wrapper .itemHover:hover {
  color: #0076a7;
}
.post-content-wrapper a:hover {
  color: #f13636;
}
.post-footer {
  color: #989a99;
  font-size: 11px;
  font-weight: normal;
}
.post-footer a {
  font-weight: normal;
  font-size: 13px;
  color: #989a99 !important;
}
.post-footer a:hover {
  color: #f13636 !important;
}
.post-footer::before {
  content: "\01F550";
  font-size: 10px;
  margin-right: 3px;
}

.introduction-content {
  width: 100%;
}
.introduction-content-wrapper {
  width: 100%;
  margin-top: 15px;
}
.introduction-title {
  display: flex;
  justify-content: center;
  text-transform: uppercase;

}

.introduction-title h2 {
  color: #ffa401;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
.introduction-body {
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  margin-bottom: 10px;
  white-space: pre-line;
}
.introduction-body figure.image_resized img {
  width: 100%;
}

.introduction-title,
.introduction-title-wrapper,
.introduction-body-wrapper,
.introduction-body,
.introduction-footer-wrapper,
.introduction-footer {
  width: 100%;
}
.image-style-align-center {
  /* display: flex; */
  justify-content: center;
  text-align: center;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
}
.introduction-body table td {
  border: transparent !important;
}
.introduction-body p a {
  color: #0076a7;
  font-weight: 500;
}

.introduction-body p a:hover {
  color: #f13636;
}

.banner-wrapper {
  height: 380px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100%;
}
.banner-title-wrapper {
  margin-top: -54px;
  color: #fff;
  font-size: 35px;
  text-shadow: 0 1px 1px #000;
  padding: 0 15px 0 15px;
  text-transform: uppercase !important
}

.links-wrapper,
.links-content-wrapper {
  width: 100%;
  min-height: 300px;
  margin-top: 30px;
}
.links-content {
  display: flex;
  flex-wrap: wrap;
}
.ant-tooltip-inner{
  white-space: pre-line;
}
.link-holder{
  margin-bottom: 10px;
}
.brand-wrapper {
  border: 1px solid #ccc;
  width: 209px;
  display: flex;
  justify-content: center;
}
.brand-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.services-wrapper,.services-content-wrapper{
    width: 100%;
}
.services-content-wrapper{
    margin-top: 15px;
}

.service-content-wrapper td {
    border: 1px solid #ccc;
    /* text-align: center; */
}

/* .table{
    display: flex;
    justify-content: center;
} */
.ant-tabs-tab {
	text-transform: uppercase;
	color: black;
	font-weight: bold;
}

.ant-tabs-tab:hover {
	color: #0076ae;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #ffa401 !important;
}
.tab-content h2 {
	text-align: center;
	font-weight: bold;
	font-size: 24px;
}
.tab-content p {
	margin-top: 10px;
	font-size: 16px;
	line-height: 20px;
	white-space: pre-line;
	text-align: justify;
}
.tab-content p a {
	margin-left: 10px;
	color: #0076a7;
	font-style: italic;
	font-weight: bold;
	font-size: 16px;
}
.custom-table .table {
	display: flex;
	justify-content: center;
}

/* .service-content-wrapper .customtabForm tr td *{
	display: unset;
} */


.service-content-wrapper .customtabForm tr td .image-style-align-center {
	display: block !important;
}

/* .customtabForm figure img {
	width: 100%;
} */

.feedback-form-content .ant-btn {
  background: rgb(255, 151, 0);
  height: 23px;
  color: white;
  text-transform: uppercase;
  border: 1px solid transparent;
  z-index: 1;
  line-height: 15px;
  width: 41px;
  font-weight: 550;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 1px 1px black;
  display: flex;
  justify-content: center;
  margin-left: 30px;
}
.feedback-form-content .ant-btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 23px;
  left: 0;
  font-weight: 600;
  z-index: -1;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
  transition: all 0.3s ease-in-out;
}
.feedback-form-content .ant-btn:hover {
  font-weight: 600;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  color: white;
}
.feedback-form-content .ant-btn:hover:after {
  top: 0;
  bottom: 0;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
}
.feedback-form-content .ant-btn:active {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.feedback-form-content .ant-btn:focus {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.feedback-form-wrapper,
.feedback-form-title-wrapper,
.feedback-form-title,
.feedback-form-content-wrapper,
.feedback-form-content {
  width: 100%;
}
.feedback-form-title h2 {
  color: #ffa401;
  font-size: 35px;
  text-transform: uppercase;
}
.feedback-form-wrapper {
  margin-top: 20px;
}

.guide-content-wrapper,
.guide-title-wrapper,
.guide-title,
.guide-wrapper {
	width: 100%;
}
.guide-content-wrapper {
	margin-top: 15px;
	margin-left: 10%;
	margin-right: 10%;
}
.guide-title h2 {
	margin-top: 15px;
	color: #ffa401;
	font-size: 28px;
	font-weight: bold;
}
.guide-title {
	display: flex;
	justify-content: center;
    text-transform: uppercase;
}
.step-content-wrapper{
    width: 80vw;
}
.step-content-wrapper .ant-card-head{
    padding: 0;
}
.card-content{
	width: 100%;
    border: 1px solid rgb(134, 130, 130);
    border-radius: 10px;
	background-color: white;
	padding: 0 15px;
}
.card-content .card-title{
	width: auto;
    border-radius: 10px;
}
.card-content .card-title span{
	background-color: white;
	position: relative;
	top:-15px;
	left: 20px;
	font-weight: 600;
	font-size: 16px;
}
.row-input {
	align-items: center;
	margin: 15px 0;
}
/* .row-input .ant-form-item-explain{
	position: absolute;
	bottom: -23px;
	font-size: 13px;
} */
.icon-addItem{
	font-size: 28px;
	cursor: pointer;
	opacity: 0.5;
	transition: 0.5s;
	margin: 0;
}
.icon-addItem:hover{
	opacity: 1;
}
.item-name span{
	/* font-weight: 600; */
	font-size: 13px;
	font-style: italic;
}
.item-name {
	font-size: 13px;
}
.item-name-1{
	margin-bottom: 5px;
}
.checkbox-label {
	font-size: 10px;
}
.checkbox-label span {
	font-size: 10px;
	font-style: italic;
}

.icon-updateItem {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	opacity: 0;
	transition: 0.5s;
}

.icon-updateItem:hover{
	opacity: 1;
}

.item-name-dgr {
	font-size: 13px;
}

*, *:before, *:after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
  }

  .form-group-print {
		display: block;
		margin-bottom: 2px;
	}
	
	.form-group-print input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0px;
		display: none;
		cursor: pointer;
	}
	
	.form-group-print label {
		position: relative;
		cursor: pointer;
	}
	
	.form-group-print label:before {
		content:'';
		-webkit-appearance: none;
		background-color: transparent;
		/* border: 1px solid black; */
		/* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
		/* padding: 5px; */
		display: inline-block;
		position: relative;
		vertical-align: middle;
		cursor: pointer;
		/* margin-right: 5px; */
	}
	
	.form-group-print input:checked + label:after {
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		left: 4px;
		width: 6px;
		height: 12px;
		border: solid black;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		        transform: rotate(45deg);
	}

	.check-box-print {
		border: '1px solid black';
		margin-right: '5px';
		display: 'inline-block'; 
		width: 13;
		height: 13;
	}
    
.ant-card-head-title {
  font-weight: bold;
  font-size: 17px;
}
.ant-card-body {
  text-align: justify;
  font-size: 16px;
  white-space: pre-line;
}
.ant-card-body a {
  text-align: justify;
  font-size: 16px;
  white-space: pre-line;
  font-size: 16px;
  font-weight: bold;
  color: #0076a7;
}
.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffa401;
}
.instructor-image-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}
.instructor-image-wrapper img {
  max-width: 80%;
}
.ant-steps-navigation .ant-steps-item::after {
  border: 1px solid #feb228;
  border-bottom: none;
  border-left: none;
}
.guide-content .ant-steps-item-container {
  opacity: 0.5;
}
.guide-content .ant-steps-item-active .ant-steps-item-container {
  opacity: 1;
}
.guide-content .ant-collapse-content-box p {
  padding-left: 4%;
}
.guide-content .ant-collapse-header {
  font-weight: bold;
}

.esid-table {
    max-width: 1800px;
    margin-inline: auto;
}
.esid-table tr:hover {
    /* cursor: pointer; */
    background-color: rgba(240, 240, 240, 0.849);
}

.esid-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}
.search-form .ant-form-item {
    margin-bottom: 15px !important;
}
.news-wrapper,
.news-title-wrapper,
.news-title,
.news-content-wrapper {
  width: 100%;
}
.news-content-wrapper {
  margin-top: 15px;
}
.news-title h2 {
  margin-top: 15px;
  color: #ffa401;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
}
.news-title {
  display: flex;
  justify-content: center;
}
.child-menu {
  padding-bottom: 0 !important;
  font-weight: bold;
}
.child-menu:hover {
  padding-bottom: 0 !important;
  color: #ffa401 !important;
}
.child-menu.ant-menu-item {
  padding-bottom: 0 !important;
}
.child-menu.ant-menu-item-selected {
  color: #ffa401 !important;
  padding-bottom: 0 !important;
}

.tab-content .post-wrapper {
  width: 100%;
  min-height: 135px;
  margin-bottom: 5px;
  display: flex;
  padding: 0 10%;
}

@media screen and (max-width: 1200px)  {
  .tab-content .post-wrapper {
    padding: 0 50px;
  }
}

.tab-content .post-cover-wrapper {
  border: 1px solid #ccc;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-content .post-cover-wrapper img {
  width: 198px;
  height: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.tab-content .post-content-wrapper {
  /* margin-left: 20px; */
  padding-right: 0px !important;
}
.tab-content .post-content-wrapper a {
  font-size: 18px;
  line-height: 24px;
  color: black;
}
.tab-content .post-content-wrapper a:hover {
  color: black;
}
.tab-content .post-content-wrapper p {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  -webkit-line-clamp: 3;
  height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.tab-content .post-content-wrapper h6 {
  margin-top: 5px;
  color: rgb(70, 70, 70);
}

.contact-detail-wrapper,
.contact-detail,
.contact-title-wrapper,
.contact-title,
.contact-content-wrapper,
.contact-info {
  width: 100%;
}
/* * {
  font-family: Arial, Helvetica, sans-serif;
} */
.contact-info p {
  font-size: 16px;
}
.contact-info {
  margin-top: 20px;
  line-height: 0.6;
  font-size: 16px;
}
.contact-info h2 {
  font-weight: bold;
  margin-bottom: 1em;
  text-transform: uppercase;
}
.contacts-content-wrapper {
  margin-top: 30px;
}

.contact-detail-wrapper,
.contact-detail,
.contact-title-wrapper,
.contact-content-wrapper {
  width: 100%;
}
.contact-content {
  white-space: pre-line;
  font-size: 16px;
  line-height: 1.2;
}
.contact-content>p:first-child{
  margin-top: 10px;
}

.contact-title-wrapper {
  border: 1px solid #ccc;
  background-color: #ddd;
  padding: 3px;
}
.contact-title {
  font-size: 16px;
  font-weight: bold;
}
.contact-detail {
  margin-right: 30px;
  margin-top: 5px;
  margin-bottom: 25px;
}
.contact-content a {
  font-size: 16px;
  color: #0076a7;
  font-weight: bold;
}
.contact-detail:last-child {
  margin-bottom: 10px !important; 
}
.login-wrapper,
.login-form-title-wrapper,
.login-form-wrapper,
.modal-content {
  width: 100%;
}
.login-form-title h2 {
  color: #ffa401;
  font-size: 22px;
  font-weight: bold;
  /* font-family: Diavlo_LIGHT; */
  text-align: center;
  /* text-shadow: 2px 2px #ccc; */
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-form .ant-form-item-has-error .ant-input,
.login-form .ant-form-item-has-error .ant-input:hover,
.login-form input,
.login-form input:hover {
  background: #e8f0fe;
}
.admin-control .ant-modal-title {
  font-weight: bold;
}
.modal-control button {
  height: 60px;
  font-size: 16px;
  font-weight: bold;
}

.airlines-wrapper,
.airlines-content-wrapper,
.control-wrapper,
.awb-tab-title-wrapper,
.form-awb,
.form-report,
.result-wrapper,
.result-title-wrapper,
.control-button-group-wrapper,
.form-status,
.form-schedule,
.form-change-pass {
  width: 100%;
}
.airlines-content .ant-tabs-tab {
  font-size: 13px;

}
.logout-btn span {
  font-size: 14px;
}
.airlines-content .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 0 !important;
}
.airlines-content .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-bottom: none !important;
  color: #fd8805 !important;
}
.airlines-content-wrapper {
  margin-top: 15px;
}
.airlines-content-wrapper .ant-tabs-tab + .ant-tabs-tab{
    margin:0;
}
.awb-first {
  width: 65px;
}
.awb-last {
  width: 180px;
}
.form-awb label {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
}
.control-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  padding-left: 20px;
  width: 100%;
}
.control {
  display: flex;
}

.awb-result-title h5 {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 15px;
  background-color: #eaeaea;
}
.awb-tab-title h5 {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.form-report label {
  font-size: 14px;
  color: #464646;
}
.form-report .flight-number {
  width: 150px;
}
.result-title h5 {
  margin-top: 10px;
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.ant-table-thead .head-awb {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  background-color: #eaeaea;
}
.ant-table-thead .head_tb_repo {
  background-color: #eaeaea;
}
.ant-descriptions-item-label {
  background-color: #fff !important;
}

.table-cutoff td,
.table-cutoff th {
  border: 1px solid #aaa !important;
}
.airlines-content .table-cutoff .ant-table-title,
.airlines-content .table-cutoff .ant-table-footer {
  background-color: #e2f2ff;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
}
.airlines-content .table-report .ant-table-title,
.airlines-content .table-report .ant-table-footer,
.airlines-content .table-report .table-report-header {
  background-color: #fff;
  text-transform: uppercase;
  text-align: left !important;
  font-weight: bold;
  color: #000;
}
.airlines-content .table-report .table-report-header {
  background-color: #e2f2ff;
}
.control-button-group-wrapper {
  margin-top: 10px;
}

.header-report-part-D {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-weight: bold;
  text-align: center;
  background-color: #ededed;
  padding: 5px;
}

.report-part-D {
  border: 1px solid #ccc;
  text-align: left;
  padding: 5px;
}
.table-cutoff .ant-table-tbody > tr:hover > td,
.table-bill .ant-table-tbody > tr:hover > td {
  background-color: #f89a2d;
}
.font-small .head_tb_repo {
    font-size: 12px;
}

.airlines-content-wrapper-large {
  width: 83%;
}

@media only screen and (max-width: 1900px) {
  .airlines-content-wrapper-large {
    width: 96%;
  }
}

.charges-result .ant-descriptions-item-label {
  width: 40%;
}
.awb-result-title p {
  font-weight: bold;
  margin-top: 12px;
  margin-left: 10px;
}
.result-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.total-row {
  border: 1px solid #f0f0f0;
  padding: 10px;
  font-weight: bold;
}
.charges-detail-result .ant-descriptions-item-label {
  font-weight: bold;
}
.awb-result-content-index {
  border: 1px solid #f0f0f0;
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-bottom: transparent;
}
.control-footer {
  display: flex;
  justify-content: center;
}
.detail-label {
  min-width: 15%;
  width: 15%;
}
.detail-content {
  width: 650px;
  white-space: pre-wrap;
}
.detail-vat {
  min-width: 15%;
  width: 15%;
}
.awb-result-wrapper {
  border: 1px solid black;
}

.style_esidContent__1bKpF {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    min-height: 100%;
    border: 1px solid #D9DEEC;
}
.style_esidContent__1bKpF .style_ant-collapse-header__35j8t {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
@media only screen and (max-width: 580px) {
    .style_esidContent__1bKpF {
        padding: 0px;
    }
}
.style_submitBtn__1dt5- {
    width: -webkit-fit-content ;
    width: -moz-fit-content ;
    width: fit-content ;
    background: rgb(255, 151, 0);
    height: 23px;
    color: white;
    text-transform: uppercase;
    border: 1px solid transparent;
    z-index: 1;
    line-height: 15px;
    font-weight: 550;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 1px 1px black;
    display: flex;
    justify-content: center;
    margin: auto;
}
.style_submitBtn__1dt5-:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 23px;
    left: 0;
    font-weight: 600;
    z-index: -1;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
    transition: all 0.3s ease-in-out;
}
.style_submitBtn__1dt5-:hover {
    font-weight: 600;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    color: white;
}
.style_submitBtn__1dt5-:hover:after {
    top: 0;
    bottom: 0;
    height: 100%;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
}
.style_submitBtn__1dt5-:active, .style_submitBtn__1dt5-:focus, .style_submitBtn__1dt5-:hover {
    color: white;
    background-color: unset;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}

.style_esid-content__1rqGA .style_ant-form-item-label__3hUwR {
    max-height: 40px;
}
.style_Panel__3DFp2 {
    /* From https://css.glass */
    margin-bottom: 10px;
    background: rgba(246, 246, 246, 0.29);
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(246, 246, 246, 0.74);
}
.style_personalForm__3_l8A {
    width: 100%;
    padding: 2px 10px;
    border-radius: 2px;
    background-color: white;
    /* border: 1px solid #D9DEEC; */
    /* -webkit-box-shadow: 0 1px 3px 1px rgba(50,65,78, 0.1);
    -moz-box-shadow: 0 1px 3px 1px rgba(50,65,78,0.1);
    box-shadow: 0 1px 3px 1px rgba(50,65,78,0.1); */
}
.style_largeForm__10861{
    min-height: 400px;
}
.style_SearchForm__Qg-w8 {
    padding: 0px 15px !important;
}
.style_labelRequired__2ErJ8::after {
    display: inline-block;
    margin-left: 5px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}
.style_EsidModal__136i4 {
    margin-top: -20px;
    /* height: min(800px, 90dvh); */
    width: clamp(270px,90%,1400px) !important;
}
.style_AddModal__19vHM {
    max-width: 1300px;
    width: clamp(240px,85%,700px) !important;
}
.style_AddSizeModal__2Xivs {
    max-width: 1300px;
    width: clamp(240px,75%,500px) !important;
}
.style_SmallGoodsTable__hiQtR {
    margin-top: 10px;
    width: 100%;
    /* height: fit-content;
    max-height: calc(100vh - 470px);
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 300px; */
}
.style_SmallAnimalsTable__CCjtT {
    margin-top: 10px;
    width: 100%;
    /* height: fit-content;
    max-height: calc(100vh - 470px);
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 215px; */
}
.style_QRContainer__2DXuC {
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    height: 70px;
    width: 70px;
    margin-inline: auto;
    border-radius: 5px;
}
.style_QRIcon__1FkfQ {
    font-size: 30px;
}
.style_QRText__2HEIg {
    font-size: 10px;
    margin-left: 0px !important;
    color: inherit;
}
@media only screen and (max-width: 767px) {
    .style_SearchForm__Qg-w8 {
        padding: 0px !important;
    }
    .style_responsiveRow__3CkVf {
        margin-inline: 0 !important;
    }
    .style_QRContainer__2DXuC {
        height: 50px;
        width: 50px;
        margin-top: 20px;
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    .style_QRIcon__1FkfQ {
        font-size: 20px;
    }
    .style_QRText__2HEIg {
        font-size: 10px;
    }
}
@media only screen and (max-width: 580px) and (max-height: 700px) {
    /* .SmallGoodsTable {
        height: 200px;
    }
    .SmallAnimalsTable {
        height: 200px;
    } */
    .style_submitBtn__1dt5- {
        font-size: 10px ;
    }
}
.right-form-container .ant-form-item-label>label {
    font-size: 16px !important;
}
.left-form-container .ant-form-item-label>label {
    font-size: 14px !important;
}
.ant-collapse-header-text {
    font-weight: 700;
}
.iata-form .ant-checkbox-disabled + span {
    cursor: default;
    color: rgba(0, 0, 0, 0.85);
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(255, 151, 0);
    border-color: rgb(255, 151, 0);
}
.ant-checkbox-input:hover + .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgb(255, 151, 0);
}
.exp-sub-title {
    font-weight: bold;
    color: #ffa401;
    font-style: italic;
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
}
.ant-form-item-control-input-content,
.ant-input-number-input-wrap {
    height: 100%;
}
/* .add-animals, 
.form-animals {
    margin-inline: calc(3% + 1px) !important;
} */
.form-container {
    grid-row-gap: 50px;
    row-gap: 50px;
    margin: 15px 3% 10px !important;
    width: 100%;
}

.submitBtn {
    width: -webkit-fit-content ;
    width: -moz-fit-content ;
    width: fit-content ;
    background: rgb(255, 151, 0);
    height: 23px;
    color: white;
    text-transform: uppercase;
    border: 1px solid transparent;
    z-index: 1;
    line-height: 15px;
    font-weight: 550;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 1px 1px black;
    display: flex;
    justify-content: center;
    margin: auto;
}
.submitBtn:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 23px;
    left: 0;
    font-weight: 600;
    z-index: -1;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
    transition: all 0.3s ease-in-out;
}
.submitBtn:hover {
    font-weight: 600;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    color: white;
}
.submitBtn:hover:after {
    top: 0;
    bottom: 0;
    height: 100%;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
}
.submitBtn:active {
    color: white;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.submitBtn:focus {
    color: white;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}

@media only screen and (max-width: 1130px) {
    .guide-content-wrapper {
        margin-inline: auto;
    }
}
.TabExpDeclConsol_formItemWrapper__1TgS7 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* print */
.TabExpDeclConsol_spaceBetween__6m5d5 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.TabExpDeclConsol_logoContainer__lPO8s {
    max-width: 130px;
}
.TabExpDeclConsol_logo__GNnsM {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.TabExpDeclConsol_titleContainer__1VgDX {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.TabExpDeclConsol_shipmentDetails__3grL0 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.TabExpDeclConsol_consolTable__1kE8k {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}
.TabExpDeclConsol_consolTable__1kE8k, .TabExpDeclConsol_consolTable__1kE8k th, .TabExpDeclConsol_consolTable__1kE8k td {
    border:1px solid black;
    text-align: center;
    padding: 4px 2px;
}
.TabExpDeclConsol_checkBoxContainer__27BnG {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-bottom: 10px;
    grid-gap: 5px;
    gap: 5px;
}
.TabExpDeclConsol_dateContainer__1MbnB {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.TabExpDeclConsol_col__1gEuq {
    border: 1px dotted rgb(119, 114, 114);
    min-height: 100px;
}
.TabExpDeclConsol_col__1gEuq:nth-of-type(2) {
    border-left: transparent;
}
.TabExpDeclConsol_colTitle__1Hnrd {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.style_MainLayout__37qK_ {
    min-height: 100dvh;
    height: 100%;
}
.style_Page__2xvT6 {
}
.style_Divider__2PNXV {
    margin: 0 !important;
}
.style_Content__3760R {
    overflow-y: auto;
    overflow-x: hidden;
    padding: min(3%,16px) !important;
    margin-bottom: 0 !important;
    background-color: rgb(240 240 250 / 60%);
    min-height: calc(100vh - 80px);
}
.style_ContentContainer__1KHMl {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #D9DEEC;
    height: 100%;
}
.style_scrollable__3akVE::-webkit-scrollbar-track {
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}
.style_scrollable__3akVE::-webkit-scrollbar {
    background-color: #F5F5F5 !important;
}
.style_scrollable__3akVE::-webkit-scrollbar-thumb {
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
}
.style_Page__2xvT6::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: transparent;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}

.style_Page__2xvT6::-webkit-scrollbar
{
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
	width: 6px;
	background-color: transparent;
}

.style_Page__2xvT6::-webkit-scrollbar-thumb
{
    -webkit-transition: all 1000ms ease-in-out;
    transition: all 1000ms ease-in-out;
	border-radius: 10px;
    background-color: transparent;
}
@media only screen and (max-width: 580px) {
    .style_Content__3760R {
        padding: 5px 0px !important;
    }
    .style_ContentContainer__1KHMl {
        padding: 5px !important;
    }
}
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.fundo{
    -webkit-animation: scales 3s alternate  infinite;
            animation: scales 3s alternate  infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
  }
  .pao-baixo{
     -webkit-animation: rotatepao 14s cubic-bezier(.1,.49,.41,.97)  infinite;
             animation: rotatepao 14s cubic-bezier(.1,.49,.41,.97)  infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
  }
  .pao-cima{
     -webkit-animation: rotatepao 7s 1s cubic-bezier(.1,.49,.41,.97)  infinite;
             animation: rotatepao 7s 1s cubic-bezier(.1,.49,.41,.97)  infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
  }
  .olhos{-webkit-animation: olhos   2s  alternate  infinite;animation: olhos   2s  alternate  infinite;
     -webkit-transform-origin: center;
             transform-origin: center;
  }
  
  .left-sparks{-webkit-animation: left-sparks   4s  alternate  infinite;animation: left-sparks   4s  alternate  infinite;
        -webkit-transform-origin: 150px 156px;
                transform-origin: 150px 156px;
  }
  
  .right-sparks{-webkit-animation: left-sparks   4s  alternate  infinite;animation: left-sparks   4s  alternate  infinite;
        -webkit-transform-origin: 310px 150px;
                transform-origin: 310px 150px;
  }
  
  .olhos{-webkit-animation: olhos   2s  alternate  infinite;animation: olhos   2s  alternate  infinite;
     -webkit-transform-origin: center;
             transform-origin: center;
  }
  @-webkit-keyframes scales{
    from { -webkit-transform: scale(0.98); transform: scale(0.98)}
    to{ -webkit-transform: scale(1); transform: scale(1)}
  }
  @keyframes scales{
    from { -webkit-transform: scale(0.98); transform: scale(0.98)}
    to{ -webkit-transform: scale(1); transform: scale(1)}
  }
  
  @-webkit-keyframes rotatepao{
    0% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg)}
    50% , 60%{ -webkit-transform:  rotate(-20deg); transform:  rotate(-20deg)}
    100%{  -webkit-transform:  rotate(0deg);  transform:  rotate(0deg) }
   
  }
  
  @keyframes rotatepao{
    0% { -webkit-transform:  rotate(0deg); transform:  rotate(0deg)}
    50% , 60%{ -webkit-transform:  rotate(-20deg); transform:  rotate(-20deg)}
    100%{  -webkit-transform:  rotate(0deg);  transform:  rotate(0deg) }
   
  }
  
  @-webkit-keyframes olhos{
    0%{
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
    }
     100%{
      -webkit-transform: rotateX(30deg);
              transform: rotateX(30deg);
    }
  }
  
  @keyframes olhos{
    0%{
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
    }
     100%{
      -webkit-transform: rotateX(30deg);
              transform: rotateX(30deg);
    }
  }
  
  @-webkit-keyframes left-sparks{
    0%{
      opacity: 0; 
    }
    
  }
  
  @keyframes left-sparks{
    0%{
      opacity: 0; 
    }
    
  }
  
  
  .main{
    min-height: 600px;
    margin: 0px auto;
    width: auto;
    max-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .path {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    -webkit-animation: dash 4s   alternate infinite;
            animation: dash 4s   alternate infinite;
  }
  
  @-webkit-keyframes dash{
     0%, 30%{
       fill: 4B4B62;
      stroke-dashoffset:   0;
    }
     80%,100%{
       fill: transparent;
      stroke-dashoffset:  -200;
    }
     
    
  }
  
  @keyframes dash{
     0%, 30%{
       fill: 4B4B62;
      stroke-dashoffset:   0;
    }
     80%,100%{
       fill: transparent;
      stroke-dashoffset:  -200;
    }
     
    
  }
.post-detail-wrapper,
.post-detail-title-wrapper,
.post-detail-title,
.post-detail-content-wrapper {
  width: 100%;
}
.post-detail-content-wrapper {
  margin-top: 15px;
}
.post-detail-content {
  text-align: justify;
}
.post-detail-title h2 {
  margin-top: 25px;
  color: #ffa401;
  font-size: 22px;
  font-weight: bold;
}

.agent-wrapper,
.agent-content-wrapper,
.control-wrapper,
.awb-tab-title-wrapper,
.form-awb,
.form-report,
.result-wrapper,
.result-title-wrapper,
.control-button-group-wrapper,
.form-status,
.form-schedule,
.form-change-pass,
.result-content-wrapper,
.ticket-wrapper,
.ticket-headers-wrapper,
.ticket-content-wrapper,
.select-header-wrapper,
.awb-tab-content {
  width: 100%;
}
.ant-form-item .ant-input-number-handler-wrap {
  display: none !important;
}
.ant-table-tbody > tr > td {
  padding: 3px 5px;
}

.detail-table {
  text-align: left !important;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  text-align: center;
}

/* .agent-content .ant-tabs-tab {
  font-size: 14px;
  background-color: #fd8805;
  border-top-right-radius: 10px;
  padding: 3px 10px;
  margin: 0px;
  text-transform: capitalize;
} */
.logout-btn {
  font-size: 16px;
  font-weight: 700;
  background-color: #fd8805;
  border-top-right-radius: 10px;
  padding: 3px 10px;
  margin: 0px;
  text-transform: capitalize;
}

.logout-btn:hover {
  background-color: #fd8805;
  border: #fd8805;
  color: #00769e;
}
.agent-content .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 0 !important;
}
.agent-content .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-bottom: none !important;
  color: #fd8805 !important;
}
.agent-content-wrapper {
  margin-top: 15px;
}
.awb-first {
  width: 65px;
}
.form-awb label {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
}
.head_tb_repo {
  font-weight: bold !important;
  background-color: #ffffff;
}
td.head_tb_repo {
  font-weight: normal !important;
}
.control-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.control {
  display: flex;
}
.qr-input-wrapper {
  background-color: #e2f2ff;
  border-bottom: 1px solid #aaa;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.qr-wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.qr-input-wrapper .qr-no {
  background-color: #d6d6d6;
  padding: 5px 5px;
}
.qr-input-wrapper .qr-title {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 5px;
}
.control-title-wrapper:first-child {
  margin-bottom: -10px;
}
.control-title-wrapper:last-child {
  margin-top: -10px;
}
.control-title {
  background-color: #cccccc;
  text-align: center;
  color: #fb4312;
  font-weight: bold;
  margin-right: 5px;
  font-size: 16px;
  margin-bottom: -10px;
}
.control-title2 {
  background-color: rgb(255, 255, 204);
  text-align: center;
  color: #000;
  font-weight: bold;
  margin-right: 5px;
  font-size: 16px;
  margin-bottom: -10px;
}
.awb-result-title h5 {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 15px;
  background-color: #eaeaea;
}
.awb-tab-title h5 {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.form-report label {
  font-size: 14px;
  color: #464646;
}
.result-title h5 {
  margin-top: 10px;
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.result-content h5 {
  margin-top: 10px;
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle ) {
  background: rgb(255, 151, 0);
  height: 23px;
  color: white;
  text-transform: uppercase;
  border: 1px solid transparent;
  z-index: 1;
  line-height: 15px;
  width: 41px;
  font-weight: 550;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 1px 1px black;
  display: flex;
  justify-content: center;
  margin-left: 30px;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 23px;
  left: 0;
  font-weight: 600;
  z-index: -1;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
  transition: all 0.3s ease-in-out;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):hover {
  font-weight: 600;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  color: white;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):hover:after {
  top: 0;
  bottom: 0;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):active {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):focus {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}

.ticket-content:first-child {
  padding: 90px 50px;
  border-left: 1px solid #aaa;
  border-top: 1px solid #aaa;
}
.ticket-content {
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
}
.ticket-fields {
  padding: 2px 4px;
  border-top: 1px solid #aaa;
  display: flex;
  align-items: center;
}
.ticket-headers:first-child {
  background-color: #ccc;
  font-weight: bold;
  color: #464646;
  padding: 5px;
  border-top: 1px solid #aaa;
}
.ticket-headers {
  background-color: #ccc;
  font-weight: bold;
  padding: 5px;
  color: #464646;
  border-right: 1px solid #aaa;
}
.hawb-result .ant-descriptions-item-label {
  width: 50%;
  background-color: white;
  font-weight: bold;
}
.customer-info {
  font-weight: bold;
}
.table-bill .ant-table-title {
  font-weight: bold;
}
.table-bill .ant-table-summary tr td {
  padding: 3px 5px;
}
.agent-content .table-cutoff .ant-table-title,
.agent-content .table-cutoff .ant-table-footer {
  background-color: #e2f2ff;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
}
.table-cutoff td.ant-table-selection-column .ant-radio {
  margin: 0;
}
.hawb-link,
.mawb-link {
  color: #0076a7;
}
.hawb-link:hover,
.mawb-link:hover {
  color: #fb4312;
}
.mawb-link {
  font-weight: bold;
}
.letter-table table td {
  border: transparent !important;
}
.table-shipment-info td.ant-table-selection-column .ant-radio {
  margin: 0;
}
.registry-footer {
  font-weight: bold;
}
.registry-notice {
  font-weight: bold;
  color: #0076a7;
  font-style: italic;
  text-align: center;
  font-size: 1rem;
}

/* eslint-disable */
@-webkit-keyframes scroll {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translate(-50%);
		transform: translate(-50%);
	}
}
@keyframes scroll {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translate(-50%);
		transform: translate(-50%);
	}
}

.slider {
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
	height: 50px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.slider::before,
.slider::after {
	content: '';
	height: 100px;
	position: absolute;
	width: 200px;
	z-index: 2;
}
.slider::after {
	right: 0;
	top: 0;
	-webkit-transform: rotateZ(180deg);
	        transform: rotateZ(180deg);
}
.slider::before {
	left: 0;
	top: 0;
}
.slider .slide-track {
	display: flex;
	-webkit-animation: scroll 30s linear infinite running;
	animation: scroll 30s linear infinite running;
}
.slider .slide {
	/* height: 50px;
	width: 100px; */
	margin-right: 0.5rem;
}
.slide img {
	width: 100px;
	height: 50px;
	object-fit: contain;
}

.awb-result-title {
  background-color: #eaeaea;
}
.result-wrapper{
    margin-top: 10px;
}
.statistic-wrapper,
.statistic-form-title-wrapper,
.statistic-form-wrapper {
  width: 100%;
}
.statistic-form-title h2 {
  color: #ffa401;
  font-size: 20px;
  font-weight: bold;
  /* font-family: Verdana, Tahoma, sans-serif; */
  text-align: center;
  text-shadow: 2px 2px #ccc;
}

.statistic-form .ant-form-item-has-error .ant-input,.statistic-form .ant-form-item-has-error .ant-input:hover,.statistic-form input,.statistic-form input:hover {
  background: #e8f0fe;
}
.form-wrapper .ant-btn {
  background: rgb(255, 151, 0);
  height: 23px;
  color: white;
  text-transform: uppercase;
  border: 1px solid transparent;
  z-index: 1;
  line-height: 15px;
  width: 41px;
  font-weight: 550;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 1px 1px black;
  display: flex;
  justify-content: center;
  margin-left: 30px;
}
.form-wrapper .ant-btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 23px;
  left: 0;
  font-weight: 600;
  z-index: -1;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
  transition: all 0.3s ease-in-out;
}
.form-wrapper .ant-btn:hover {
  font-weight: 600;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  color: white;
}
.form-wrapper .ant-btn:hover:after {
  top: 0;
  bottom: 0;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
}
.form-wrapper .ant-btn:active {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.form-wrapper .ant-btn:focus {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.statistic-wrapper,
.statistic-form-title-wrapper,
.statistic-form-wrapper {
  width: 100%;
}

.change-password a {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: #0076a7;
}
.statistic-controls {
  display: flex;
  justify-content: center;
}

.table-payment .ant-table-thead tr .ant-table-cell {
  color: #fb4312;
  font-weight: bold;
  background-color: #e6e6e7;
  line-height: 14px;
}
.marginRight {
  margin-right: 20px;
}

.checkout-content {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.checkout-content-wrapper {
  display: flex;
  justify-content: center;
  margin: auto 30px;
}
.ant-tabs.ant-tabs-top {
  width: 100%;
}
.checkout-content .ant-tabs-tab {
  font-size: 14px;
  background-color: #fd8805;
  border-top-right-radius: 10px;
  padding: 3px 10px;
  text-transform: capitalize;
  margin-top: 20px;
}
.checkout-content .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 0 !important;
}
.checkout-content .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-bottom: none !important;
  color: white !important;
}
.checkout-content .ant-tabs-tab + .ant-tabs-tab {
  margin: 20px 0 0 5px;
  padding: 11px 15px;
  font-weight: bold;
}
.form-receiver .ant-form-item-label label,
.form-einvoice-query .ant-form-item-label label,
.form-einvoice-payment .ant-form-item-label label,
.form-invoice-info .ant-form-item-label label,
.awb-payment .ant-form-item .ant-form-item-label label {
  color: #fb4312;
  font-size: 12px;
  font-weight: 600;
}
.form-receiver .ant-input,
.form-receiver .ant-picker,
.form-invoice-info .ant-input,
.form-invoice-info .ant-picker,
.awb-payment .ant-input,
.ant-input .ant-picker {
  width: 100%;
  height: 40px;
}
.btn-payment {
  color: white;
  background: #fd8805;
  border-radius: 4px;
}
.btn-payment > span {
  font-size: 14px;
}
.btn-payment:hover {
  color: white;
  background: #fd8805;
}
.horizontal-controls {
  display: flex;
  justify-content: space-between;
}
.row-selected {
  background-color: #dcf4ff;
}
.checkout-content .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #dcf4ff;
}
.hawb-fee-content .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #dcf4ff;
}
.table-payment .ant-table-footer {
  font-weight: bold;
}
.table-agent-list {
  cursor: default;
}
.table-agent-list .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #dcf4ff;
}
td.awb-table-currency {
  text-align: right !important;
}
.table-payment-charge-detail .ant-table-summary {
  background-color: #ddd;
  font-weight: bold;
}
.table-payment .ant-table-summary {
  background-color: #ddd;
  font-weight: bold;
}
.table-payment-charge-detail .ant-table-summary td {
  border: 1px solid #000;
}
.barcode-wrapper {
  height: 176mm;
  width: 250mm;
  margin-left: 20px;
  display: flex;
  justify-content: center;
}
.barcode-wrapper h3 {
  font-size: 32px;
}
.account-info .ant-form-item-label label {
  font-size: 13px;
}
.table-payment .ant-table-summary {
  line-height: 10px;
}
.exit-btn {
  font-size: 14px;
  background-color: #fd8805;
  border-top-right-radius: 10px;
  text-transform: capitalize;
  margin-top: 25px;
  height: 44px;
}
.exit-btn:hover {
  background-color: #fd8805;
}

.exit-btn * {
  font-weight: bold;
}

.right-form-container .ant-form-item-label>label {
    font-size: 16px !important;
}
.left-form-container .ant-form-item-label>label {
    font-size: 14px !important;
}
.ant-collapse-header-text {
    font-weight: 700;
}

.exp-sub-title {
    font-weight: bold;
    color: #ffa401;
    font-style: italic;
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
}
.ant-form-item-control-input-content,
.ant-input-number-input-wrap {
    height: 100%;
}
/* .add-goods, 
.form-goods {
    margin-inline: calc(3% + 1px);
} */
.declDgrForm-container {
    grid-row-gap: 50px;
    row-gap: 50px;
    margin: 0 calc(3% + 1px) 10px !important;
}

.submitBtn {
    width: -webkit-fit-content ;
    width: -moz-fit-content ;
    width: fit-content ;
    background: rgb(255, 151, 0);
    height: 23px;
    color: white;
    text-transform: uppercase;
    border: 1px solid transparent;
    z-index: 1;
    line-height: 15px;
    font-weight: 550;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 1px 1px black;
    display: flex;
    justify-content: center;
    margin: auto;
}
.submitBtn:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 23px;
    left: 0;
    font-weight: 600;
    z-index: -1;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
    transition: all 0.3s ease-in-out;
}
.submitBtn:hover {
    font-weight: 600;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    color: white;
}
.submitBtn:hover:after {
    top: 0;
    bottom: 0;
    height: 100%;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
}
.submitBtn:active {
    color: white;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.submitBtn:focus {
    color: white;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}


@media only screen and (max-width: 1130px) {
    .guide-content-wrapper {
        margin-inline: auto;
    }
}
.esid-list_container {
    /* margin: 0 calc(3% + 1px) 50px !important; */
    margin-top: 10px;
}
.search-form,
.esid-table {
    max-width: 1800px;
    margin-inline: auto;
}
.esid-table tr:hover {
    cursor: pointer;
    background-color: rgba(240, 240, 240, 0.849);
}

.esid-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}
.search-form .ant-form-item {
    margin-bottom: 15px !important;
}

.ant-btn-list {
    background: rgb(255, 151, 0);
    height: 23px;
    color: white;
    text-transform: uppercase;
    border: 1px solid transparent;
    z-index: 1;
    line-height: 15px;
    width: 41px;
    font-weight: 550;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 1px 1px black;
    margin-right: 10px;
}
.esid-list_container {
    /* margin: 0 calc(3% + 1px) 50px !important; */
    margin-top: 10px;
}
.search-form,
.esid-table {
    max-width: 1800px;
    margin-inline: auto;
}
.esid-table tr:hover {
    cursor: pointer;
    background-color: rgba(240, 240, 240, 0.849);
}

.esid-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}
.search-form .ant-form-item {
    margin-bottom: 15px !important;
}
.payment_paymentLogoContainer__2Orcv {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 15px;
}
.payment_paymentLogoWrapper__2g9HP {
    width: 150px;
    height: 100px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment_paymentLogo__3bm0p {
    width: 100%;
    height: auto;
    max-width: 70px;
}
.payment_paymentOptions__1-hIq {
    display: flex;
    flex-direction: column;
}
.payment_paymentOption__395ne {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}
.payment_paymentIcon__3eUs1 {
    width: 100%;
    height: auto;
    max-width: 20px;
}

/* paymentDetail */
.payment_paymentDetailContainer__3YVEF,
.payment_billInforContainer__3Eol7,
.payment_receiptDetailContainer__IIoDk {
    padding: 0px 0px 10px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    margin-bottom: 20px;
}
.payment_detailContainer__3SZ7l {
    display: flex;
    flex-direction: column;
}
.payment_detailItem__3ohLx {
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
}
.AppQR {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Button {
  color: white;
  background-color: blue;
  padding: 10px;
  width: 100px;
  font-weight: 800;
  letter-spacing: 2;
  cursor: pointer;
  border-radius: 10px;
  -webkit-transform: all 1s cubic-bezier(0.42, 0, 0.17, 0.71);
          transform: all 1s cubic-bezier(0.42, 0, 0.17, 0.71);
  margin: 10px;
}

.Button:hover {
  background-color: red;
}

.dialog {
  padding: 20px;
  background: white;
  position: absolute;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(37, 37, 37, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-content {
  background-color: white;
  margin: 0;
  width: 400px;
  height: 400px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
}

.close {
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

.close > button {
  height: 36px;
  width: 36px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
  padding-bottom: 5px;
}

@-webkit-keyframes anime {
  0% {
    font-size: 10px;
  }
  30% {
    font-size: 15px;
  }
  100% {
    font-size: 12px;
  }
}

@keyframes anime {
  0% {
    font-size: 10px;
  }
  30% {
    font-size: 15px;
  }
  100% {
    font-size: 12px;
  }
}

.detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0;
  height: 40px;
  border-bottom: 1px dashed;
  -webkit-animation:slidein 2s;
          animation:slidein 2s;
}
.detail-first-child {
  border-top: 1px dashed;
  margin-top: 20px;
}

.detail-header {
  font-weight: bold;
  font-size: 1rem;
}

.detail-content {
  font-size: 1rem;
  padding-left: 5px;
}

.green {
  color: rgb(153, 255, 0);
}
.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.red {
  color: red;
}

.message {
  font-size: 0.8rem;
}
.small {
  font-size: 0.9rem;
}


@-webkit-keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}


@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.errorMessage{
  display: flex;
  align-content: center;
  align-items: center;
}
.sider-container {
    background-color: white !important;
    position: relative !important;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    z-index: 100;
    transition: all 200ms ease-in-out;
}
.sider-container .collapse-btn {
    position: fixed;
    cursor: pointer;
    top: 15px;
    left: 260px;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    z-index: 102;
    color: #ffca00 !important;
    font-size: 20px !important;
    transition: all ease-in-out 200ms;
}
.sider-container .collapse-btn.active{
    left: 100px;
    -webkit-transform: rotate(180deg) translateX(-50%);
            transform: rotate(180deg) translateX(-50%);
}
.sider-container .ant-menu-item {
    position: relative;
    overflow: visible !important;
    margin-inline: 0 !important;
}
.ant-menu-title-content span {
    color: #888 !important;
}
.ant-menu-item-group-title {
    color: #5f5f5f !important;
    font-size: 11px !important;
    font-weight: 600;
}
.ant-menu-item-icon {
    color: #ffca00 !important;
    font-size: 20px !important;
}
.ant-menu-inline-collapsed .ant-menu-item .ant-menu-title-content span {
    display: none;
}
@media only screen and (min-width: 786px) {
    .sider-container .ant-menu-item {
        padding-left: 30px !important;
    }
}
@media only screen and (max-width: 767px) {
    .sider-container {
        height: 100vh;
        position: fixed !important;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 101;
    }
    .sider-container .collapse-btn {
        position: absolute;
    }
    .sider-container .collapse-btn.active {
        top: 15px;
        -webkit-transform: 
            translateX(calc(-80px));
                transform: 
            translateX(calc(-80px));
    }
    .sider-container .collapse-btn.false {
        top: 15px;
        -webkit-transform: 
            translateX(calc(-100% - 30px));
                transform: 
            translateX(calc(-100% - 30px));
    }
    .ant-collapse-content > .ant-collapse-content-box,
    .searchForm .ant-col,
    .exportInformation .ant-col {
        padding: 0 !important;
    }
    .logo {
        display: flex !important;
    }
}
@media only screen and (max-width: 992px) {
    .sider-container .collapse-btn {
        -webkit-transform: 
            translateX(calc(-40px));
                transform: 
            translateX(calc(-40px));
    }
    .sider-container .collapse-btn.active {
        -webkit-transform: 
            translateX(calc(-80px));
                transform: 
            translateX(calc(-80px));
    }
    .logo {
        display: flex !important;
    }
}

.sider-container .ant-menu-item::before {
    content: '';
    position: absolute;
    background: #b1a1ff;
    height: 100%;
    width: 4px;
    border-radius:  0px 4px 4px 0px;
    top: 0;
    left: 0%;
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}
.sider-container .ant-menu-item:hover ,
.sider-container .ant-menu-item-selected  {
    background-color: #ece8ff93 !important;
    opacity: 1;
}
.sider-container .ant-menu-item:hover .ant-menu-title-content span,
.sider-container .ant-menu-item-selected .ant-menu-title-content span {
    color: #e7bd12 !important;
}
.sider-container .ant-menu-item:hover:before,
.sider-container .ant-menu-item-selected:before {
    opacity: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.dashboard_changeLang__nJsGF {
    color: #006688 !important;
    text-transform: uppercase;
    background-color: #fd8805;
    margin: auto 0;
}
.dashboard_changeLang__nJsGF:hover {
    color: #5350e6 !important;
    cursor: pointer;
}
.dashboard_changeLang__nJsGF[aria-checked="true"]{
    background-color: #5350e6;
}

.dashboard_submitBtn__2JcLS {
    width: -webkit-fit-content ;
    width: -moz-fit-content ;
    width: fit-content ;
    background: rgb(255, 151, 0);
    height: 23px;
    color: white;
    text-transform: uppercase;
    border: 1px solid transparent;
    z-index: 1;
    line-height: 15px;
    font-weight: 550;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 1px 1px black;
    display: flex;
}
.dashboard_submitBtn__2JcLS:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 23px;
    left: 0;
    font-weight: 600;
    z-index: -1;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
    transition: all 0.3s ease-in-out;
}
.dashboard_submitBtn__2JcLS:hover {
    font-weight: 600;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    color: white;
}
.dashboard_submitBtn__2JcLS:hover:after {
    top: 0;
    bottom: 0;
    height: 100%;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
}
.dashboard_submitBtn__2JcLS:active {
    color: white;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.dashboard_submitBtn__2JcLS:focus {
    color: white;
    background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.dashboard_Header__SGtXj {
    position: -webkit-sticky;
    position: sticky;
    padding: 10px;
    height: 60px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.16), 0 1px 10px 0 rgba(0,0,0,.12);
}
/* @media only screen and(max-width:500px){
    .logo {
        display: flex !important;
    }
} */

html::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

html::-webkit-scrollbar
{
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
	width: 8px;
    background-color: #F5F5F5 !important;
}

html::-webkit-scrollbar-thumb
{
    -webkit-transition: all 1000ms ease-in-out;
    transition: all 1000ms ease-in-out;
	border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.115);
}
p {
    text-align: left;
}

.main-admin .ant-form-item {
    margin-bottom: 0 !important;
}
.main-admin .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label {
    padding-bottom: 3px !important;
}
.main-admin .ant-tabs-ink-bar {
    background: #ffa401 !important;
}

.table-cutoff .ant-table-container {
    margin-top: 10px;
    min-height: 350px;
}
.table-cutoff .ant-table-body::-webkit-scrollbar-track
{
	border-radius: 10px;
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.table-cutoff .ant-table-body::-webkit-scrollbar
{
	width: 6px;
    height: 4px;
    background-color: #F5F5F5 !important;
}

.table-cutoff .ant-table-body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
}
.customScroll::-webkit-scrollbar-track
{
	border-radius: 10px;
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.customScroll::-webkit-scrollbar
{
	width: 6px;
    background-color: #F5F5F5 !important;
}

.customScroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
}
.ant-card-body p {
    margin-bottom: 0 !important;
}
/* .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 5px !important;
} */
.smallTable-list {
    padding-inline: 5px !important;
}
.contentContainer .ant-tabs {
    min-height: calc(95% - 100px);
}

.smallTable-list .ant-row > div {
    display: flex;
}
/* equal height for dgr card and livAnm card */
.smallTable-list .ant-row > div > div > div, 
.smallTable-list .ant-row > div > div > div .ant-col {
    height: 100% !important;
}
.table-esid .ant-table-container {
    min-height: unset !important;
}
.personalForm .ant-form-item-required::before {
    display: none !important;
}
.ant-checkbox-checked::after {
    border: 1px solid rgb(255, 151, 0) !important;
}
div#tempReqSelect_list ~ .rc-virtual-list .ant-select-item-option-content {
    white-space: normal !important;
}
@media only screen and (max-width: 768px) {
    .contentContainer .ant-tabs {
        min-height: calc(95% - 210px);
    }
    .smallTable .ant-card-body {
        padding: 10px !important;
    }
}
@media only screen and (max-width: 580px) {
    .smallTable .ant-card-body {
        padding: 10px 5px !important;
    }
    .smallTable .ant-row {
        margin-inline: 0 !important;
    }
    .ant-modal-body {
        padding: 24px 10px !important
    }
    .ant-form-item-label > label {
        min-height: 37px;
        white-space: nowrap;
    }
    .search-form .ant-col:nth-child(2) .ant-row {
        padding-inline: 0px !important;
    }
    .search-form .ant-form-item {
        margin-bottom: 0px !important;
    }
    .searchBtn-container .ant-form-item-label{
        display: none;
    }
    .searchBtn-container .ant-form-item-control{
        margin-top: 10px !important;
    }
}
@media only screen and (max-width: 370px) {
    .esidInput {
        flex: 0 0 99% !important;
        max-width: 99% !important;
    }
    .btnGroup{
        flex: 0 0 99% !important;
        max-width: 99% !important;
    }
    .btnGroup label {
        display: none;
    }
}

#airway-bill_datFltOri {
    color: black !important;
    font-weight: bold !important;
}

