/* eslint-disable */

@-moz-keyframes scroll {
	0% {
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-o-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translate(-50%);
		-moz-transform: translate(-50%);
		-o-transform: translate(-50%);
		-ms-transform: translate(-50%);
		transform: translate(-50%);
	}
}
@-webkit-keyframes scroll {
	0% {
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-o-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translate(-50%);
		-moz-transform: translate(-50%);
		-o-transform: translate(-50%);
		-ms-transform: translate(-50%);
		transform: translate(-50%);
	}
}
@-o-keyframes scroll {
	0% {
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-o-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translate(-50%);
		-moz-transform: translate(-50%);
		-o-transform: translate(-50%);
		-ms-transform: translate(-50%);
		transform: translate(-50%);
	}
}
@keyframes scroll {
	0% {
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-o-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translate(-50%);
		-moz-transform: translate(-50%);
		-o-transform: translate(-50%);
		-ms-transform: translate(-50%);
		transform: translate(-50%);
	}
}

.slider {
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
	height: 50px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.slider::before,
.slider::after {
	content: '';
	height: 100px;
	position: absolute;
	width: 200px;
	z-index: 2;
}
.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}
.slider::before {
	left: 0;
	top: 0;
}
.slider .slide-track {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-animation: scroll 30s linear infinite running;
	-moz-animation: scroll 30s linear infinite running;
	-o-animation: scroll 30s linear infinite running;
	-ms-animation: scroll 30s linear infinite running;
	animation: scroll 30s linear infinite running;
}
.slider .slide {
	/* height: 50px;
	width: 100px; */
	margin-right: 0.5rem;
}
.slide img {
	width: 100px;
	height: 50px;
	-o-object-fit: contain;
	object-fit: contain;
}
