.banner-wrapper {
  height: 380px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100%;
}
.banner-title-wrapper {
  margin-top: -54px;
  color: #fff;
  font-size: 35px;
  text-shadow: 0 1px 1px #000;
  padding: 0 15px 0 15px;
  text-transform: uppercase !important
}
