.introduction-title h2 {
  color: #ffa401;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
.introduction-body {
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  margin-bottom: 10px;
  white-space: pre-line;
}
.introduction-body figure.image_resized img {
  width: 100%;
}

.introduction-title,
.introduction-title-wrapper,
.introduction-body-wrapper,
.introduction-body,
.introduction-footer-wrapper,
.introduction-footer {
  width: 100%;
}
.image-style-align-center {
  /* display: flex; */
  justify-content: center;
  text-align: center;
  margin-block-end: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-inline-start: 0;
}
.introduction-body table td {
  border: transparent !important;
}
.introduction-body p a {
  color: #0076a7;
  font-weight: 500;
}

.introduction-body p a:hover {
  color: #f13636;
}
