.contact-detail-wrapper,
.contact-detail,
.contact-title-wrapper,
.contact-title,
.contact-content-wrapper,
.contact-info {
  width: 100%;
}
/* * {
  font-family: Arial, Helvetica, sans-serif;
} */
.contact-info p {
  font-size: 16px;
}
.contact-info {
  margin-top: 20px;
  line-height: 0.6;
  font-size: 16px;
}
.contact-info h2 {
  font-weight: bold;
  margin-bottom: 1em;
  text-transform: uppercase;
}
.contacts-content-wrapper {
  margin-top: 30px;
}
