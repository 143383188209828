.home-content-wrapper {
  padding: 0 !important;
  background: white;
  display: flex;
  justify-content: center;
}

.home-control-wrapper {
  width: 100%;
  /*   position: relative;
 */
  display: flex;
  justify-content: center;
}
.home-control {
  /*   position: absolute;
 */ /* top: -50%; */
  width: 997px;
  border: 1px solid #ccc;
}
.ant-carousel .slick-dots-bottom {
  bottom: 80px;
}
.home-control {
  background: rgba(190, 181, 181, 0.4);
  padding: 20px;
}
.tab {
  float: left;
  display: flex;
  justify-content: space-between;
  height: 45px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}
.tab:first-child {
  margin: 0 5px 0 0;
  background: white;
}
.tab:first-child h2 {
  color: #fda400;
  font-weight: 700;
}
.tab:not(:first-child) {
  margin: 0 5px 0 0;
  background: rgba(255, 251, 251, 0.5);
}
.tab:not(:first-child) a h2 {
  color: rgba(30, 145, 253, 0.5);
  font-weight: 700;
}
.home-control-wrapper .tab:not(:first-child):hover {
  background: rgba(255, 251, 251, 0.616);
}
.home-control-wrapper .tab:not(:first-child):hover a h2 {
  color: rgba(30, 145, 253, 0.747);
}
.tab-content-container {
  width: 100%;
  background: white;
  float: left;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 25px 15px 25px 25px;
  line-height: 25px;
  justify-content: left;
}
.ant-form-item-label > label {
  font-size: 16px;
}
.home-control .ant-btn {
  background: rgb(255, 151, 0);
  height: 23px;
  color: white;
  text-transform: uppercase;
  border: 1px solid transparent;
  z-index: 1;
  line-height: 15px;
  width: 41px;
  font-weight: 550;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 1px 1px black;
  display: flex;
  justify-content: center;
  margin-left: 30px;
  width: fit-content;
}
.home-control .ant-btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 23px;
  left: 0;
  font-weight: 600;
  z-index: -1;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
  transition: all 0.3s ease-in-out;
}
.home-control .ant-btn:hover {
  font-weight: 600;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  color: white;
}
.home-control .ant-btn:hover:after {
  top: 0;
  bottom: 0;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
}
.home-control .ant-btn:active {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.home-control .ant-btn:focus {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.ant-radio {
  margin-left: 40px;
}
.ant-radio-wrapper {
  font-size: 16px !important;
}
.content-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.post-content-wrapper {
  /* margin-left: 15px; */
}
.ant-input-number-input-wrap {
  background-color: #e8f0fe;
}
.ant-input-number-input {
  height: 100%;
}
@media screen and (max-width: 500px) {
  .home-control-wrapper {
    height: 210px;
  }
}
@media screen and (max-width: 768px) {
  .home-control {
    margin-top: 60px;
    margin-bottom: 20px;
    border: 1px solid #808080;
  }
  .home-control {
    background: #ffffff;
    padding: 20px;
  }

  .home-control-wrapper .tab-show {
    /* display: none; */
    padding: 0 !important;
    margin: 0;
    font-size: 12px;
    height: 35px;
  }
  .home-control-wrapper .tab-show h2 {
    margin: 0 !important;
  }

  .tab-content-container {
    padding: 0;
    height: none;
  }

  .content-wrapper {
    margin-bottom: 0;
  }
  .home-btn .ant-btn {
    width: 100% !important;
    border-color: #ccc;
    color: #337ab7;
    font-weight: 700;
  }

  .ant-col.ant-col-22.home-content.ant-col-md-22.ant-col-lg-6 {
    padding: 0;
  }
}
