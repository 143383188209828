.services-wrapper,.services-content-wrapper{
    width: 100%;
}
.services-content-wrapper{
    margin-top: 15px;
}

.service-content-wrapper td {
    border: 1px solid #ccc;
    /* text-align: center; */
}

/* .table{
    display: flex;
    justify-content: center;
} */