.statistic-wrapper,
.statistic-form-title-wrapper,
.statistic-form-wrapper {
  width: 100%;
}

.change-password a {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: #0076a7;
}
.statistic-controls {
  display: flex;
  justify-content: center;
}
