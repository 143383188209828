.sider-container {
    background-color: white !important;
    position: relative !important;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.15), 0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    z-index: 100;
    transition: all 200ms ease-in-out;
}
.sider-container .collapse-btn {
    position: fixed;
    cursor: pointer;
    top: 15px;
    left: 260px;
    transform: translateX(50%);
    z-index: 102;
    color: #ffca00 !important;
    font-size: 20px !important;
    transition: all ease-in-out 200ms;
}
.sider-container .collapse-btn.active{
    left: 100px;
    transform: rotate(180deg) translateX(-50%);
}
.sider-container .ant-menu-item {
    position: relative;
    overflow: visible !important;
    margin-inline: 0 !important;
}
.ant-menu-title-content span {
    color: #888 !important;
}
.ant-menu-item-group-title {
    color: #5f5f5f !important;
    font-size: 11px !important;
    font-weight: 600;
}
.ant-menu-item-icon {
    color: #ffca00 !important;
    font-size: 20px !important;
}
.ant-menu-inline-collapsed .ant-menu-item .ant-menu-title-content span {
    display: none;
}
@media only screen and (min-width: 786px) {
    .sider-container .ant-menu-item {
        padding-left: 30px !important;
    }
}
@media only screen and (max-width: 767px) {
    .sider-container {
        height: 100vh;
        position: fixed !important;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 101;
    }
    .sider-container .collapse-btn {
        position: absolute;
    }
    .sider-container .collapse-btn.active {
        top: 15px;
        transform: 
            translateX(calc(-80px));
    }
    .sider-container .collapse-btn.false {
        top: 15px;
        transform: 
            translateX(calc(-100% - 30px));
    }
    .ant-collapse-content > .ant-collapse-content-box,
    .searchForm .ant-col,
    .exportInformation .ant-col {
        padding: 0 !important;
    }
    .logo {
        display: flex !important;
    }
}
@media only screen and (max-width: 992px) {
    .sider-container .collapse-btn {
        transform: 
            translateX(calc(-40px));
    }
    .sider-container .collapse-btn.active {
        transform: 
            translateX(calc(-80px));
    }
    .logo {
        display: flex !important;
    }
}

.sider-container .ant-menu-item::before {
    content: '';
    position: absolute;
    background: #b1a1ff;
    height: 100%;
    width: 4px;
    border-radius:  0px 4px 4px 0px;
    top: 0;
    left: 0%;
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}
.sider-container .ant-menu-item:hover ,
.sider-container .ant-menu-item-selected  {
    background-color: #ece8ff93 !important;
    opacity: 1;
}
.sider-container .ant-menu-item:hover .ant-menu-title-content span,
.sider-container .ant-menu-item-selected .ant-menu-title-content span {
    color: #e7bd12 !important;
}
.sider-container .ant-menu-item:hover:before,
.sider-container .ant-menu-item-selected:before {
    opacity: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
}