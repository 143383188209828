.airlines-wrapper,
.airlines-content-wrapper,
.control-wrapper,
.awb-tab-title-wrapper,
.form-awb,
.form-report,
.result-wrapper,
.result-title-wrapper,
.control-button-group-wrapper,
.form-status,
.form-schedule,
.form-change-pass {
  width: 100%;
}
.airlines-content .ant-tabs-tab {
  font-size: 13px;

}
.logout-btn span {
  font-size: 14px;
}
.airlines-content .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 0 !important;
}
.airlines-content .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-bottom: none !important;
  color: #fd8805 !important;
}
.airlines-content-wrapper {
  margin-top: 15px;
}
.airlines-content-wrapper .ant-tabs-tab + .ant-tabs-tab{
    margin:0;
}
.awb-first {
  width: 65px;
}
.awb-last {
  width: 180px;
}
.form-awb label {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
}
.control-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  padding-left: 20px;
  width: 100%;
}
.control {
  display: flex;
}

.awb-result-title h5 {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 15px;
  background-color: #eaeaea;
}
.awb-tab-title h5 {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.form-report label {
  font-size: 14px;
  color: #464646;
}
.form-report .flight-number {
  width: 150px;
}
.result-title h5 {
  margin-top: 10px;
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.ant-table-thead .head-awb {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  background-color: #eaeaea;
}
.ant-table-thead .head_tb_repo {
  background-color: #eaeaea;
}
.ant-descriptions-item-label {
  background-color: #fff !important;
}

.table-cutoff td,
.table-cutoff th {
  border: 1px solid #aaa !important;
}
.airlines-content .table-cutoff .ant-table-title,
.airlines-content .table-cutoff .ant-table-footer {
  background-color: #e2f2ff;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
}
.airlines-content .table-report .ant-table-title,
.airlines-content .table-report .ant-table-footer,
.airlines-content .table-report .table-report-header {
  background-color: #fff;
  text-transform: uppercase;
  text-align: left !important;
  font-weight: bold;
  color: #000;
}
.airlines-content .table-report .table-report-header {
  background-color: #e2f2ff;
}
.control-button-group-wrapper {
  margin-top: 10px;
}

.header-report-part-D {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-weight: bold;
  text-align: center;
  background-color: #ededed;
  padding: 5px;
}

.report-part-D {
  border: 1px solid #ccc;
  text-align: left;
  padding: 5px;
}
.table-cutoff .ant-table-tbody > tr:hover > td,
.table-bill .ant-table-tbody > tr:hover > td {
  background-color: #f89a2d;
}
.font-small .head_tb_repo {
    font-size: 12px;
}

.airlines-content-wrapper-large {
  width: 83%;
}

@media only screen and (max-width: 1900px) {
  .airlines-content-wrapper-large {
    width: 96%;
  }
}