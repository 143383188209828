.footer .navigator {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
  border: none;
  line-height: 18px;
  background: #ffa401;
}
.footer .navigator li {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
}
.footer .navigator li a {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.footer .navigator li a:hover {
  color: rgba(0, 0, 0, 0.85);
}
@media screen and (max-width: 700px){
  .footer .footer-links {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;
    text-align: center;
  }
}
.footer .footer-links,
.footer-slides {
  width: 100%;
}
.footer .footer-links {
  margin-top: 20px;
  background-color: #ffa401;
  display: flex;
  justify-content: space-evenly;
}
