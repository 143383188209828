.login-wrapper,
.login-form-title-wrapper,
.login-form-wrapper,
.modal-content {
  width: 100%;
}
.login-form-title h2 {
  color: #ffa401;
  font-size: 22px;
  font-weight: bold;
  /* font-family: Diavlo_LIGHT; */
  text-align: center;
  /* text-shadow: 2px 2px #ccc; */
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-form .ant-form-item-has-error .ant-input,
.login-form .ant-form-item-has-error .ant-input:hover,
.login-form input,
.login-form input:hover {
  background: #e8f0fe;
}
.admin-control .ant-modal-title {
  font-weight: bold;
}
.modal-control button {
  height: 60px;
  font-size: 16px;
  font-weight: bold;
}
