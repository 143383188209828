.brand-wrapper {
  border: 1px solid #ccc;
  width: 209px;
  display: flex;
  justify-content: center;
}
.brand-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
