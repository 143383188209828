.contact-detail-wrapper,
.contact-detail,
.contact-title-wrapper,
.contact-content-wrapper {
  width: 100%;
}
.contact-content {
  white-space: pre-line;
  font-size: 16px;
  line-height: 1.2;
}
.contact-content>p:first-child{
  margin-top: 10px;
}

.contact-title-wrapper {
  border: 1px solid #ccc;
  background-color: #ddd;
  padding: 3px;
}
.contact-title {
  font-size: 16px;
  font-weight: bold;
}
.contact-detail {
  margin-right: 30px;
  margin-top: 5px;
  margin-bottom: 25px;
}
.contact-content a {
  font-size: 16px;
  color: #0076a7;
  font-weight: bold;
}
.contact-detail:last-child {
  margin-bottom: 10px !important; 
}