.post-detail-wrapper,
.post-detail-title-wrapper,
.post-detail-title,
.post-detail-content-wrapper {
  width: 100%;
}
.post-detail-content-wrapper {
  margin-top: 15px;
}
.post-detail-content {
  text-align: justify;
}
.post-detail-title h2 {
  margin-top: 25px;
  color: #ffa401;
  font-size: 22px;
  font-weight: bold;
}
