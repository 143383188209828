.paymentLogoContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 15px;
}
.paymentLogoWrapper {
    width: 150px;
    height: 100px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paymentLogo {
    width: 100%;
    height: auto;
    max-width: 70px;
}
.paymentOptions {
    display: flex;
    flex-direction: column;
}
.paymentOption {
    display: flex;
    gap: 10px;
    align-items: center;
}
.paymentIcon {
    width: 100%;
    height: auto;
    max-width: 20px;
}

/* paymentDetail */
.paymentDetailContainer,
.billInforContainer,
.receiptDetailContainer {
    padding: 0px 0px 10px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    margin-bottom: 20px;
}
.detailContainer {
    display: flex;
    flex-direction: column;
}
.detailItem {
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
}