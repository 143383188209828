.MainLayout {
    min-height: 100dvh;
    height: 100%;
}
.Page {
}
.Divider {
    margin: 0 !important;
}
.Content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: min(3%,16px) !important;
    margin-bottom: 0 !important;
    background-color: rgb(240 240 250 / 60%);
    min-height: calc(100vh - 80px);
}
.ContentContainer {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #D9DEEC;
    height: 100%;
}
.scrollable::-webkit-scrollbar-track {
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}
.scrollable::-webkit-scrollbar {
    background-color: #F5F5F5 !important;
}
.scrollable::-webkit-scrollbar-thumb {
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
}
.Page::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: transparent;
    transition: all 200ms ease-in-out;
}

.Page::-webkit-scrollbar
{
    transition: all 200ms ease-in-out;
	width: 6px;
	background-color: transparent;
}

.Page::-webkit-scrollbar-thumb
{
    transition: all 1000ms ease-in-out;
	border-radius: 10px;
    background-color: transparent;
}
@media only screen and (max-width: 580px) {
    .Content {
        padding: 5px 0px !important;
    }
    .ContentContainer {
        padding: 5px !important;
    }
}