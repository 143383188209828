.links-wrapper,
.links-content-wrapper {
  width: 100%;
  min-height: 300px;
  margin-top: 30px;
}
.links-content {
  display: flex;
  flex-wrap: wrap;
}
.ant-tooltip-inner{
  white-space: pre-line;
}
.link-holder{
  margin-bottom: 10px;
}