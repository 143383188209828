.agent-wrapper,
.agent-content-wrapper,
.control-wrapper,
.awb-tab-title-wrapper,
.form-awb,
.form-report,
.result-wrapper,
.result-title-wrapper,
.control-button-group-wrapper,
.form-status,
.form-schedule,
.form-change-pass,
.result-content-wrapper,
.ticket-wrapper,
.ticket-headers-wrapper,
.ticket-content-wrapper,
.select-header-wrapper,
.awb-tab-content {
  width: 100%;
}
.ant-form-item .ant-input-number-handler-wrap {
  display: none !important;
}
.ant-table-tbody > tr > td {
  padding: 3px 5px;
}

.detail-table {
  text-align: left !important;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  text-align: center;
}

/* .agent-content .ant-tabs-tab {
  font-size: 14px;
  background-color: #fd8805;
  border-top-right-radius: 10px;
  padding: 3px 10px;
  margin: 0px;
  text-transform: capitalize;
} */
.logout-btn {
  font-size: 16px;
  font-weight: 700;
  background-color: #fd8805;
  border-top-right-radius: 10px;
  padding: 3px 10px;
  margin: 0px;
  text-transform: capitalize;
}

.logout-btn:hover {
  background-color: #fd8805;
  border: #fd8805;
  color: #00769e;
}
.agent-content .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 0 !important;
}
.agent-content .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-bottom: none !important;
  color: #fd8805 !important;
}
.agent-content-wrapper {
  margin-top: 15px;
}
.awb-first {
  width: 65px;
}
.form-awb label {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
}
.head_tb_repo {
  font-weight: bold !important;
  background-color: #ffffff;
}
td.head_tb_repo {
  font-weight: normal !important;
}
.control-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.control {
  display: flex;
}
.qr-input-wrapper {
  background-color: #e2f2ff;
  border-bottom: 1px solid #aaa;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.qr-wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.qr-input-wrapper .qr-no {
  background-color: #d6d6d6;
  padding: 5px 5px;
}
.qr-input-wrapper .qr-title {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 5px;
}
.control-title-wrapper:first-child {
  margin-bottom: -10px;
}
.control-title-wrapper:last-child {
  margin-top: -10px;
}
.control-title {
  background-color: #cccccc;
  text-align: center;
  color: #fb4312;
  font-weight: bold;
  margin-right: 5px;
  font-size: 16px;
  margin-bottom: -10px;
}
.control-title2 {
  background-color: rgb(255, 255, 204);
  text-align: center;
  color: #000;
  font-weight: bold;
  margin-right: 5px;
  font-size: 16px;
  margin-bottom: -10px;
}
.awb-result-title h5 {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 15px;
  background-color: #eaeaea;
}
.awb-tab-title h5 {
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.form-report label {
  font-size: 14px;
  color: #464646;
}
.result-title h5 {
  margin-top: 10px;
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.result-content h5 {
  margin-top: 10px;
  color: #fb4312;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle ) {
  background: rgb(255, 151, 0);
  height: 23px;
  color: white;
  text-transform: uppercase;
  border: 1px solid transparent;
  z-index: 1;
  line-height: 15px;
  width: 41px;
  font-weight: 550;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 1px 1px black;
  display: flex;
  justify-content: center;
  margin-left: 30px;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 23px;
  left: 0;
  font-weight: 600;
  z-index: -1;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
  transition: all 0.3s ease-in-out;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):hover {
  font-weight: 600;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  color: white;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):hover:after {
  top: 0;
  bottom: 0;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):active {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.awb-tab-content .ant-btn:not(#createBtn, .nostyle):focus {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}

.ticket-content:first-child {
  padding: 90px 50px;
  border-left: 1px solid #aaa;
  border-top: 1px solid #aaa;
}
.ticket-content {
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
}
.ticket-fields {
  padding: 2px 4px;
  border-top: 1px solid #aaa;
  display: flex;
  align-items: center;
}
.ticket-headers:first-child {
  background-color: #ccc;
  font-weight: bold;
  color: #464646;
  padding: 5px;
  border-top: 1px solid #aaa;
}
.ticket-headers {
  background-color: #ccc;
  font-weight: bold;
  padding: 5px;
  color: #464646;
  border-right: 1px solid #aaa;
}
.hawb-result .ant-descriptions-item-label {
  width: 50%;
  background-color: white;
  font-weight: bold;
}
.customer-info {
  font-weight: bold;
}
.table-bill .ant-table-title {
  font-weight: bold;
}
.table-bill .ant-table-summary tr td {
  padding: 3px 5px;
}
.agent-content .table-cutoff .ant-table-title,
.agent-content .table-cutoff .ant-table-footer {
  background-color: #e2f2ff;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
}
.table-cutoff td.ant-table-selection-column .ant-radio {
  margin: 0;
}
.hawb-link,
.mawb-link {
  color: #0076a7;
}
.hawb-link:hover,
.mawb-link:hover {
  color: #fb4312;
}
.mawb-link {
  font-weight: bold;
}
.letter-table table td {
  border: transparent !important;
}
.table-shipment-info td.ant-table-selection-column .ant-radio {
  margin: 0;
}
.registry-footer {
  font-weight: bold;
}
.registry-notice {
  font-weight: bold;
  color: #0076a7;
  font-style: italic;
  text-align: center;
  font-size: 1rem;
}
