.header .header-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 20px 10px rgba(0, 33, 59, 0.04);
}
.ant-layout-header {
  padding: 0;
  margin: 0;
}

.nav-banner-content-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.header .logo-wrapper,
.navigator-wrapper {
  display: flex;
  align-items: center;
}
.banner-nav-wrapper ul {
  display: flex;
  justify-content: center;
}
.nav-banner-content-email {
  color: #ffa401;
}
/* label.ant-radio-wrapper{
  flex-direction: row-reverse;
} */
/* label.ant-radio-wrapper .ant-radio{
  margin-left: 5px;
} */
@media screen and (max-width: 992px) {
  .ant-col.ant-col-3.logo-wrapper {
    display: none;
  }
  .nav-banner-content-wrapper {
    background: #fda400;
  }
  .nav-banner-content-phone h4 {
    color: #fff !important;
  }
  .header .logo-wrapper,
  .navigator-wrapper {
    height: 100%;
    min-width: 70px;
    margin: 0;
  }
  .nav-banner-content-email {
    display: none;
  }
  .banner-nav-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .banner-nav-wrapper span {
    margin-right: 10px;
  }
}

.header .navigator {
  width: 100%;
  padding-bottom: 5px;
  border: none;
  text-transform: uppercase;
}
.header .navigator li {
  color: #ffa401;
  font-weight: bold;
  font-size: 18px;
}
.header .navigator li a {
  color: #ffa401;
  font-weight: bold;
  font-size: 18px;
}

@media screen and (max-width: 1355px) {
  .header .navigator li {
    color: #ffa401;
    font-weight: bold;
    font-size: 14px;
  }
  .header .navigator li a {
    color: #ffa401;
    font-weight: bold;
    font-size: 14px;
  }
}

.header .nav-banner-content {
  font-size: 18px;
  margin-right: 10px;
}
.header .navigator li div::after {
  content: "\25BC";
  font-size: 13px;
}
.header .nav-banner-content a {
  font-size: 16px;
  color: #fd8805;
  text-transform: uppercase;
  font-weight: bold;
}
.header .change-lang {
  color: #006688 !important;
  text-transform: uppercase;
}
.header .change-lang:hover {
  color: #5350e6 !important;
  cursor: pointer;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border: transparent;
  padding-bottom: 2px !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  padding-bottom: 2px !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  padding-bottom: 4px !important;
}
.ant-menu-vertical.ant-menu-sub {
  padding: 5px;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  margin: 5px;
  border-bottom: 0.5px dashed #ddd;
  transition: transform 0.3s;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item:hover {
  background: #ffdc9b;
  transform: translateX(10px);
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item a {
  color: #006688 !important;
}
.header .change-lang {
  background-color: #fd8805;
}
.header .ant-switch-checked {
  background-color: #5350e6;
}
