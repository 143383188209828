.statistic-wrapper,
.statistic-form-title-wrapper,
.statistic-form-wrapper {
  width: 100%;
}
.statistic-form-title h2 {
  color: #ffa401;
  font-size: 20px;
  font-weight: bold;
  /* font-family: Verdana, Tahoma, sans-serif; */
  text-align: center;
  text-shadow: 2px 2px #ccc;
}

.statistic-form .ant-form-item-has-error .ant-input,.statistic-form .ant-form-item-has-error .ant-input:hover,.statistic-form input,.statistic-form input:hover {
  background: #e8f0fe;
}
.form-wrapper .ant-btn {
  background: rgb(255, 151, 0);
  height: 23px;
  color: white;
  text-transform: uppercase;
  border: 1px solid transparent;
  z-index: 1;
  line-height: 15px;
  width: 41px;
  font-weight: 550;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 1px 1px black;
  display: flex;
  justify-content: center;
  margin-left: 30px;
}
.form-wrapper .ant-btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 23px;
  left: 0;
  font-weight: 600;
  z-index: -1;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
  transition: all 0.3s ease-in-out;
}
.form-wrapper .ant-btn:hover {
  font-weight: 600;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  color: white;
}
.form-wrapper .ant-btn:hover:after {
  top: 0;
  bottom: 0;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
}
.form-wrapper .ant-btn:active {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}
.form-wrapper .ant-btn:focus {
  color: white;
  background-image: linear-gradient(315deg, #337ab7 0%, #337ab7 74%);
}