.charges-result .ant-descriptions-item-label {
  width: 40%;
}
.awb-result-title p {
  font-weight: bold;
  margin-top: 12px;
  margin-left: 10px;
}
.result-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.total-row {
  border: 1px solid #f0f0f0;
  padding: 10px;
  font-weight: bold;
}
.charges-detail-result .ant-descriptions-item-label {
  font-weight: bold;
}
.awb-result-content-index {
  border: 1px solid #f0f0f0;
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-bottom: transparent;
}
.control-footer {
  display: flex;
  justify-content: center;
}
.detail-label {
  min-width: 15%;
  width: 15%;
}
.detail-content {
  width: 650px;
  white-space: pre-wrap;
}
.detail-vat {
  min-width: 15%;
  width: 15%;
}
.awb-result-wrapper {
  border: 1px solid black;
}
