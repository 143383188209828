.tab-content .post-wrapper {
  width: 100%;
  min-height: 135px;
  margin-bottom: 5px;
  display: flex;
  padding: 0 10%;
}

@media screen and (max-width: 1200px)  {
  .tab-content .post-wrapper {
    padding: 0 50px;
  }
}

.tab-content .post-cover-wrapper {
  border: 1px solid #ccc;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-content .post-cover-wrapper img {
  width: 198px;
  height: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.tab-content .post-content-wrapper {
  /* margin-left: 20px; */
  padding-right: 0px !important;
}
.tab-content .post-content-wrapper a {
  font-size: 18px;
  line-height: 24px;
  color: black;
}
.tab-content .post-content-wrapper a:hover {
  color: black;
}
.tab-content .post-content-wrapper p {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  -webkit-line-clamp: 3;
  height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.tab-content .post-content-wrapper h6 {
  margin-top: 5px;
  color: rgb(70, 70, 70);
}
