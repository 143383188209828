.guide-content-wrapper,
.guide-title-wrapper,
.guide-title,
.guide-wrapper {
	width: 100%;
}
.guide-content-wrapper {
	margin-top: 15px;
	margin-left: 10%;
	margin-right: 10%;
}
.guide-title h2 {
	margin-top: 15px;
	color: #ffa401;
	font-size: 28px;
	font-weight: bold;
}
.guide-title {
	display: flex;
	justify-content: center;
    text-transform: uppercase;
}
.step-content-wrapper{
    width: 80vw;
}
.step-content-wrapper .ant-card-head{
    padding: 0;
}
.card-content{
	width: 100%;
    border: 1px solid rgb(134, 130, 130);
    border-radius: 10px;
	background-color: white;
	padding: 0 15px;
}
.card-content .card-title{
	width: auto;
    border-radius: 10px;
}
.card-content .card-title span{
	background-color: white;
	position: relative;
	top:-15px;
	left: 20px;
	font-weight: 600;
	font-size: 16px;
}
.row-input {
	align-items: center;
	margin: 15px 0;
}
/* .row-input .ant-form-item-explain{
	position: absolute;
	bottom: -23px;
	font-size: 13px;
} */
.icon-addItem{
	font-size: 28px;
	cursor: pointer;
	opacity: 0.5;
	transition: 0.5s;
	margin: 0;
}
.icon-addItem:hover{
	opacity: 1;
}
.item-name span{
	/* font-weight: 600; */
	font-size: 13px;
	font-style: italic;
}
.item-name {
	font-size: 13px;
}
.item-name-1{
	margin-bottom: 5px;
}
.checkbox-label {
	font-size: 10px;
}
.checkbox-label span {
	font-size: 10px;
	font-style: italic;
}

.icon-updateItem {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	opacity: 0;
	transition: 0.5s;
}

.icon-updateItem:hover{
	opacity: 1;
}

.item-name-dgr {
	font-size: 13px;
}

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
  }

  .form-group-print {
		display: block;
		margin-bottom: 2px;
	}
	
	.form-group-print input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0px;
		display: none;
		cursor: pointer;
	}
	
	.form-group-print label {
		position: relative;
		cursor: pointer;
	}
	
	.form-group-print label:before {
		content:'';
		-webkit-appearance: none;
		background-color: transparent;
		/* border: 1px solid black; */
		/* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
		/* padding: 5px; */
		display: inline-block;
		position: relative;
		vertical-align: middle;
		cursor: pointer;
		/* margin-right: 5px; */
	}
	
	.form-group-print input:checked + label:after {
		content: '';
		display: block;
		position: absolute;
		top: -1px;
		left: 4px;
		width: 6px;
		height: 12px;
		border: solid black;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}

	.check-box-print {
		border: '1px solid black';
		margin-right: '5px';
		display: 'inline-block'; 
		width: 13;
		height: 13;
	}
    