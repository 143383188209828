
html::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

html::-webkit-scrollbar
{
    transition: all 200ms ease-in-out;
	width: 8px;
    background-color: #F5F5F5 !important;
}

html::-webkit-scrollbar-thumb
{
    transition: all 1000ms ease-in-out;
	border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.115);
}
p {
    text-align: left;
}

.main-admin .ant-form-item {
    margin-bottom: 0 !important;
}
.main-admin .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label {
    padding-bottom: 3px !important;
}
.main-admin .ant-tabs-ink-bar {
    background: #ffa401 !important;
}

.table-cutoff .ant-table-container {
    margin-top: 10px;
    min-height: 350px;
}
.table-cutoff .ant-table-body::-webkit-scrollbar-track
{
	border-radius: 10px;
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.table-cutoff .ant-table-body::-webkit-scrollbar
{
	width: 6px;
    height: 4px;
    background-color: #F5F5F5 !important;
}

.table-cutoff .ant-table-body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
}
.customScroll::-webkit-scrollbar-track
{
	border-radius: 10px;
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.customScroll::-webkit-scrollbar
{
	width: 6px;
    background-color: #F5F5F5 !important;
}

.customScroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
}
.ant-card-body p {
    margin-bottom: 0 !important;
}
/* .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 5px !important;
} */
.smallTable-list {
    padding-inline: 5px !important;
}
.contentContainer .ant-tabs {
    min-height: calc(95% - 100px);
}

.smallTable-list .ant-row > div {
    display: flex;
}
/* equal height for dgr card and livAnm card */
.smallTable-list .ant-row > div > div > div, 
.smallTable-list .ant-row > div > div > div .ant-col {
    height: 100% !important;
}
.table-esid .ant-table-container {
    min-height: unset !important;
}
.personalForm .ant-form-item-required::before {
    display: none !important;
}
.ant-checkbox-checked::after {
    border: 1px solid rgb(255, 151, 0) !important;
}
div#tempReqSelect_list ~ .rc-virtual-list .ant-select-item-option-content {
    white-space: normal !important;
}
@media only screen and (max-width: 768px) {
    .contentContainer .ant-tabs {
        min-height: calc(95% - 210px);
    }
    .smallTable .ant-card-body {
        padding: 10px !important;
    }
}
@media only screen and (max-width: 580px) {
    .smallTable .ant-card-body {
        padding: 10px 5px !important;
    }
    .smallTable .ant-row {
        margin-inline: 0 !important;
    }
    .ant-modal-body {
        padding: 24px 10px !important
    }
    .ant-form-item-label > label {
        min-height: 37px;
        white-space: nowrap;
    }
    .search-form .ant-col:nth-child(2) .ant-row {
        padding-inline: 0px !important;
    }
    .search-form .ant-form-item {
        margin-bottom: 0px !important;
    }
    .searchBtn-container .ant-form-item-label{
        display: none;
    }
    .searchBtn-container .ant-form-item-control{
        margin-top: 10px !important;
    }
}
@media only screen and (max-width: 370px) {
    .esidInput {
        flex: 0 0 99% !important;
        max-width: 99% !important;
    }
    .btnGroup{
        flex: 0 0 99% !important;
        max-width: 99% !important;
    }
    .btnGroup label {
        display: none;
    }
}

#airway-bill_datFltOri {
    color: black !important;
    font-weight: bold !important;
}
