.menu-logo {
  margin-right: 5px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffdc9b;
}
@media screen and (max-width: 992px) {
  .icon-close {
    color: #9e2e2e;
  }
  .icon-menu {
    color: #fda400;
  }
  .icon-menu:hover {
    color: #eeba5a;
  }
  .ant-menu-submenu-title:hover {
    color: #fff;
  }
  .ant-menu-submenu-title,
  .ant-menu-submenu-title i {
    color: #fff;
    text-transform: uppercase;
  }
  .ant-menu-submenu-title:hover i {
    color: #fff !important;
  }
  .ant-menu-item,
  .ant-menu-item-selected a {
    color: #006688;
  }
  .ant-menu-item:hover {
    background: #ec8a46;
  }
  .ant-menu-item:hover > .ant-menu-submenu-title i {
    color: #fff;
  }
  .ant-menu-item:hover a {
    color: #fff;
  }
  .ant-menu-item:hover > .ant-menu-submenu-title i {
    color: #fff !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #ec8a46;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    color: #fff;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-color: transparent;
  }
}
