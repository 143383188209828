.ant-tabs-tab {
	text-transform: uppercase;
	color: black;
	font-weight: bold;
}

.ant-tabs-tab:hover {
	color: #0076ae;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #ffa401 !important;
}
.tab-content h2 {
	text-align: center;
	font-weight: bold;
	font-size: 24px;
}
.tab-content p {
	margin-top: 10px;
	font-size: 16px;
	line-height: 20px;
	white-space: pre-line;
	text-align: justify;
}
.tab-content p a {
	margin-left: 10px;
	color: #0076a7;
	font-style: italic;
	font-weight: bold;
	font-size: 16px;
}
.custom-table .table {
	display: flex;
	justify-content: center;
}

/* .service-content-wrapper .customtabForm tr td *{
	display: unset;
} */


.service-content-wrapper .customtabForm tr td .image-style-align-center {
	display: block !important;
}

/* .customtabForm figure img {
	width: 100%;
} */
